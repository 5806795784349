import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
import api from '../../util/api';
import helper from '../../util/helper';
import history from '../../util/history';
// import * as RootNavigation from '../../../RootNavigation';
import { getPelaporanFail, getPelaporanSuccess, getPengambilanUnitDetailFail, getPengambilanUnitDetailSuccess, submitPelaporanFail, submitPelaporanSuccess, submitPengambilanUnitFail, submitPengambilanUnitSuccess } from './actions';

  import * as constants from './constants';
  
  import makeSelectWastePickupFormManifestScreen from './selector';
  
  export function* doSubmitPengambilanUnit(action) {
    const {id_branch, origin_screen} = action;
    const dataset = yield select(makeSelectWastePickupFormManifestScreen());
    try {
      const params = {
          id_pengambilan_limbah: dataset.id_pengambilan_limbah,
          code_manifest: dataset.code_manifest,
          manifest_path: dataset.manifest_file,
      };

      // console.log(params);

      const response = yield call(api.manifest.submitPickupManifest, params);
      yield put(
        submitPengambilanUnitSuccess(response.data, dataset.id_aset),
      );
      helper.successToast("Data berhasil disimpan");
      console.log("res:", response.data);

      // save current pelaporan
      // if(origin_screen == 'pelaporan-detail'){
      //   api.saveCurrInputPelaporan(dataset.id_aset, response.data.id_pelaporan);
      //   history.push(origin_screen, {id_branch: id_branch, id_item: dataset.id_item, new_data: false});
      // }else{
      //   history.push(origin_screen);
      // }

      setTimeout(() => {
        history.replace('waste-pickup'); 
      }, 1000);
      
  
    } catch (err) {
      console.log(err);
      helper.errorToast("Gagal Menyimpan data, silahkan hub admin");
      yield put(submitPengambilanUnitFail(err));
    }
  }

  export function* doGetPengambilanManifest(action) {
    const dataset = yield select(makeSelectWastePickupFormManifestScreen());
    try {
      const params = {
        // id_approval: dataset.id_approval,
        id_pengambilan_limbah: dataset.id_pengambilan_limbah,
        // jns_approval_code: dataset.jns_approval_code
      };

      const response = yield call(api.manifest.getPickupManifest, params);
      const {success} = response.data;
  
      yield put(
        getPengambilanUnitDetailSuccess(success),
      );
    } catch (err) {
      console.log(err);
      helper.errorToast("Terjadi kegagalan proses ambil data pastikan koneksi internet anda normal atau segera hubungi admin");
      yield put(getPengambilanUnitDetailFail(err));
    }
  }

  export function* doSaveDetail(action) {
    const dataset = yield select(makeSelectWastePickupFormManifestScreen());
    try {
      api.saveToLocalDB('wastePickup', {
        no_kendaraan: dataset.no_kendaraan,
        tujuan: dataset.tujuan,
        code_manifest: dataset.code_manifest,
        notes: dataset.notes,
        manifest_name: dataset.manifest_name,
        manifest_file: dataset.manifest_file,
        dtApproval: dataset.dtApproval,
        dtAmbilLimbah: dataset.dtAmbilLimbah,
      });
  
    } catch (err) {
      console.log(err);
      helper.errorToast("Gagal Menyimpan data, silahkan hub admin");
    }
  }

  // Individual exports for testing
  export default function* wastePickupFormManifestScreenSaga() {
    yield all([
      takeLatest(constants.SUBMIT_PENGAMBILAN_UNIT, doSubmitPengambilanUnit),
      takeLatest(constants.GET_PENGAMBILAN_UNIT_DETAIL, doGetPengambilanManifest),
      takeLatest(constants.SAVE_DETAIL, doSaveDetail),
    ]);
  }
  