import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
  import api from '../../util/api';
  import helper from '../../util/helper';
  import { forgetPass, forgetPassFail, forgetPassSuccess } from './actions';
  import * as constants from './constants';
  
  import makeSelectForgetPassScreen from './selector';

  export function* doForgetPass(action) {
    // console.log('masuk saga:', action);
    const dataset = yield select(makeSelectForgetPassScreen());
    // console.log(dataset);
    try {
      const payloads = {
        email: dataset.email
      };

      api.setGToken(dataset.g_token); //save google recaptha token

      console.log("payloads:", payloads);
      const response = yield call(api.forgetPass, payloads);
      console.log("res-api:", response);
      const { success } = response.data;
      if(response.status == 200){
        yield put(forgetPassSuccess(success));
      }else{
        yield put(forgetPassFail(success));
      }
      

    } catch (err) {
      console.log("err:", err);
      yield put(forgetPassFail("Email anda belum sesuai."));
    }
  }
  
  // Individual exports for testing
  export default function* forgetPassScreenSaga() {
    yield all([
      takeLatest(constants.FORGET_PASS, doForgetPass)
    ]);
  }
  