import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  console.log("reset state action");
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function submitPengambilanUnit() {
  return {
    type: constants.SUBMIT_PENGAMBILAN_UNIT
  };
}

export function submitPengambilanUnitFail(error) {
  return {
    type: constants.SUBMIT_PENGAMBILAN_UNIT_FAIL,
    error
  };
}

export function submitPengambilanUnitSuccess(results, id_aset) {
  return {
    type: constants.SUBMIT_PENGAMBILAN_UNIT_SUCCESS,
    results
  };
}

export function setManifest(manifest) {
  return {
    type: constants.SET_MANIFEST,
    manifest
  };
}

export function delManifest() {
  return {
    type: constants.DEL_MANIFEST
  };
}

export function getPengambilanUnitDetail() {
  return {
    type: constants.GET_PENGAMBILAN_UNIT_DETAIL
  };
}

export function getPengambilanUnitDetailFail(error) {
  return {
    type: constants.GET_PENGAMBILAN_UNIT_DETAIL_FAIL,
    error
  };
}

export function getPengambilanUnitDetailSuccess(dtManifest) {
  return {
    type: constants.GET_PENGAMBILAN_UNIT_DETAIL_SUCCESS,
    dtManifest
  };
}

export function saveDetail() {
  return {
    type: constants.SAVE_DETAIL
  };
}