import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';

import api from '../../util/api';
import helper from '../../util/helper';
import history from '../../util/history';
// import * as RootNavigation from '../../../RootNavigation';
import { getUserPrivilegesFail, getUserPrivilegesSuccess, loginFail, loginSuccess } from './actions';
import * as constants from './constants';

import makeSelectLoginScreen from './selector';

  export function* doLogin(action) {
    // console.log('masuk saga:', action);
    const dataset = yield select(makeSelectLoginScreen());
    // console.log(dataset);
    try {
      const payloads = {
        email: dataset.username,
        password: dataset.password,
        token_device: action.token_device,
        name_device: action.user_agent,
        ip_device: action.ip_address
      };

      // api.setGToken(dataset.g_token); //save google recaptha token
      // console.log("payloads:", payloads);
      const response = yield call(api.login, payloads);
      // console.log("res-api:", response);
      const { success } = response.data;
      yield put(loginSuccess(success));
      // action.navigation.navigate('welcome');
    } catch (err) {
      console.log("err:", err);
      yield put(loginFail());
    }
  }

  export function* doGetUserPrivileges(action) {
    const dataset = yield select(makeSelectLoginScreen());
    try {
      const response = yield call(api.user.getPrivileges, dataset.user.akun_id);
      const {success} = response.data;
  
      yield put(
        getUserPrivilegesSuccess(success),
      );
      // save user data
      api.setUserData(dataset.user);
      // redirect here
      history.push('/welcome');
      // TODO

    } catch (err) {
      console.log(err);
      helper.errorToast("Terjadi kegagalan proses ambil data pastikan koneksi internet anda normal atau segera hubungi admin");
      yield put(getUserPrivilegesFail(err));
    }
  }
  
  // Individual exports for testing
  export default function* loginScreenSaga() {
    yield all([
      takeLatest(constants.DO_LOGIN, doLogin),
      takeLatest(constants.DO_LOGIN_SUCCESS, doGetUserPrivileges),
    ]);
  }
  