import logo from './logo.svg';
import './App.css';
import React, {useState, useEffect} from "react";
import {onMessageListener, requestForToken, messaging} from './firebase';
import { getMessaging, onMessage } from "firebase/messaging";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';

import Main from './screens/Main';
import helper from './util/helper';
import api from './util/api';


function App() {
  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload) => {
      console.log('Received fcm message ', payload);
      let msg = JSON.stringify(payload);
      localStorage.setItem('siloli:notif', msg);
      helper.infoToast("Ada notifikasi baru telah masuk.", 3000, 'top');
    });

    return unsubscribe;
  },[]);

  const handleGoogleCaptcha = (token) => {
    console.log('g-token', token);
    api.setGToken(token);
  };

  return (
    
    <GoogleReCaptchaProvider 
    reCaptchaKey="6LeezX8pAAAAALMDBnS0ee8QqZKNdn_SvUYM982z"
    scriptProps={{
      async: false, // optional, default to false,
      defer: false, // optional, default to false
      appendTo: 'head', // optional, default to "head", can be "head" or "body",
      nonce: undefined // optional, default undefined
    }}
  >
    <div className="App">
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React Jhon
        </a>
      </header>
      <Box>Hello world</Box> */}
      <Main/>
    </div>
    
    <GoogleReCaptcha onVerify={handleGoogleCaptcha}/>
  </GoogleReCaptchaProvider>
  );
}

export default App;
