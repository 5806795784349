import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
 
  import * as constants from './constants';
  import api from '../../util/api';
  import helper from '../../util/helper';
  import history from '../../util/history';
  // import * as RootNavigation from '../../../RootNavigation';
  
  import makeSelectPostAnnounceFormDetailScreen from './selector';
import { getDetailTodoFail, getDetailTodoSuccess, submitDetailFail, submitDetailSuccess, submitPreventifFail, submitPreventifSuccess } from './actions';
import makeSelectGlobalVar from '../../util/selector';
  
  export function* doGetPreventifTodo(action) {
    const {id_realisasi_preventif, id_inventaris} = action;
    try {
      const response = yield call(api.preventif.getPreventifTodo, id_realisasi_preventif, id_inventaris);
      yield put(
        getDetailTodoSuccess(response.data),
      );
      console.log("form-res:", response.data);
    } catch (err) {
      console.log(err);
      helper.errorToast("Terjadi kegagalan proses ambil data pastikan koneksi internet anda normal atau segera hubungi admin");
      yield put(getDetailTodoFail(err));
    }
  }

  export function* doSubmitDetail(action) {
    const {idx_detail} = action;
    const dataset = yield select(makeSelectPostAnnounceFormDetailScreen());
    const master = yield select(makeSelectGlobalVar());
    try {
      let nm_unit = "";
      const idxUnit = master.list_units.findIndex(result => result.id === dataset.id_unit);
      if(idxUnit >= 0) nm_unit = master.list_units[idxUnit].nama;
      
      let nm_lokasi = "";
      const idxLok = master.list_lokasi.findIndex(result => result.id === dataset.id_lokasi);
      if(idxLok >= 0) nm_lokasi = master.list_lokasi[idxLok].nama;

      const params = {
        id_unit: dataset.id_unit,
        nm_unit: nm_unit,
        id_lokasi: dataset.id_lokasi,
        nm_lokasi: nm_lokasi,
        noted: dataset.noted
      };

      // console.log(idx_detail, params);
      if(helper.isNull(idx_detail)) {
        const cur_details = dataset.saved_details;
        // console.log('saga_cur_details', cur_details)
        // cur_details.push(params);
        api.saveToLocalDB('postAnnounceDetail', [...cur_details, params]);
      } else {
        const cur_details = dataset.saved_details;
        // console.log('edit_saga_cur_details', cur_details)
        api.saveToLocalDB('postAnnounceDetail', [...cur_details.slice(0, idx_detail), params, ...cur_details.slice(idx_detail + 1)]);
      }

      yield put(
        submitDetailSuccess(),
      );
      helper.successToast("Data berhasil disimpan");
      console.log("res:", );
      
      setTimeout(() => {
        history.replace('post-announce-form',{id_pengumuan: dataset.id_pengumuan}); 
      }, 1000);
  
    } catch (err) {
      console.log(err);
      helper.errorToast("Gagal Menyimpan data, silahkan hub admin");
      yield put(submitDetailFail(err));
    }
  }

  // Individual exports for testing
  export default function* postAnnounceFormDetailScreenSaga() {
    yield all([
      takeLatest(constants.GET_DETAIL_TODO, doGetPreventifTodo),
      takeLatest(constants.SUBMIT_DETAIL, doSubmitDetail),
    ]);
  }
  