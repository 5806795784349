import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
 
  import * as constants from './constants';
  import api from '../../util/api';
  import helper from '../../util/helper';
  
  import makeSelectPostAnnounceFormVendorScreen from './selector';
import { getPegawaiFail, getPegawaiSuccess, getPengumumanFail, getPengumumanSuccess, submitCallVendorFail, submitCallVendorSuccess, submitPegawaiFail, submitPegawaiSuccess, submitPengumumanFail, submitPengumumanSuccess } from './actions';
import moment from 'moment';
import history from '../../util/history';

  export function* doGetPegumuman(action) {
    const dataset = yield select(makeSelectPostAnnounceFormVendorScreen());
    try {

      const params = {
        id_kirim_pengumuman: action.id_pengumuman
      };

      const response = yield call(api.announce.getRequestDetail, params);
      const {success} = response.data;
  
      yield put(
        getPengumumanSuccess(success),
      );
    } catch (err) {
      console.log(err);
      helper.errorToast("Terjadi kegagalan proses ambil data pastikan koneksi internet anda normal atau segera hubungi admin");
      yield put(getPengumumanFail(err));
    }
  }

  export function* doSubmitCallVendor(action) {
    const dataset = yield select(makeSelectPostAnnounceFormVendorScreen());
    try {
      const tgl_penugasan = moment(dataset.tgl_penugasan).format("YYYY-MM-DD HH:mm:ss");
      
      const params = {
        id_pengumuman_req_header: dataset.id_pengumuman_req_header,
        id_pegawai: dataset.id_pegawai,
        id_vendor: dataset.id_vendor,
        tgl_penugasan: tgl_penugasan,
      };

      if(helper.isEmpty(params.noted)) delete params.noted;

      const response = yield call(api.announce.submitCallVendor, params);
      const {success} = response.data;
      
      // if(success){
      //   const user = api.getUserData();
      //   const updated_user = {...user, email: dataset.email, hp: dataset.no_hp, user_name: dataset.username}
      //   api.setUserData(updated_user);
      // }

      yield put(
        submitCallVendorSuccess(success),
      );
      helper.successToast("Data berhasil disimpan");

      api.clearItemLocalDB('postAnnounceDetail');
      api.clearItemLocalDB('postAnnounce');
      history.replace('post-announce');

    } catch (err) {
      console.log(err);
      helper.errorToast("Terjadi kegagalan proses ambil data pastikan koneksi internet anda normal atau segera hubungi admin");
      yield put(submitCallVendorFail(err));
    }
  }

  export function* doSaveDetail(action) {
    const {idx_detail} = action;
    const dataset = yield select(makeSelectPostAnnounceFormVendorScreen());
    try {
      const cur_details = dataset.saved_details;
      console.log('saga_cur_details', cur_details)
      api.saveToLocalDB('postAnnounceDetail', [...cur_details]);
  
    } catch (err) {
      console.log(err);
      helper.errorToast("Gagal Menyimpan data, silahkan hub admin");
    }
  }

  export function* doSetEditData(action) {
    const {idx_detail} = action;
    const dataset = yield select(makeSelectPostAnnounceFormVendorScreen());
    try {
      const cur_details = dataset.saved_details;
      console.log('saga_cur_details', cur_details)
      api.saveToLocalDB('postAnnounceDetail', [...cur_details]);
      api.saveToLocalDB('postAnnounce', {
        id_pengumuman: dataset.id_pengumuman,
        expired_date: dataset.expired_date,
        post_date: dataset.post_date,
        notes: dataset.notes,
      });
      // helper.successToast("Data berhasil disimpan");
      // console.log("res:", );
  
    } catch (err) {
      console.log(err);
      helper.errorToast("Gagal Menyimpan data, silahkan hub admin");
    }
  }
  
  // Individual exports for testing
  export default function* postAnnounceFormScreenSaga() {
    yield all([
      takeLatest(constants.GET_PENGUMUMAN, doGetPegumuman),
      takeLatest(constants.SUBMIT_CALL_VENDOR, doSubmitCallVendor),
      takeLatest(constants.DEL_SAVED_DETAIL, doSaveDetail),
      takeLatest(constants.GET_PENGUMUMAN_SUCCESS, doSetEditData),
      takeLatest(constants.SET_SAVED_DETAIL, doSetEditData),
    ]);
  }
  