import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
import * as constants from './constants';
import api from '../../util/api';
import helper from '../../util/helper';
// import * as RootNavigation from '../../../RootNavigation';
import makeSelectNotificationScreen from './selector';
import { getNotificationsFail, getNotificationsSuccess } from './actions';
  
  export function* doGetNotifications(action) {
    const dataset = yield select(makeSelectNotificationScreen());
    try {
      const params ={
        id_notif: action.id_notif
      };

      const response = yield call(api.user.notifications, params);
      const {success, data} = response.data;
      
      console.log("r-notif:", data);

      yield put(
        getNotificationsSuccess((helper.isEmpty(data) ? [] : data)),
      );
    } catch (err) {
      console.log(err);
      helper.errorToast("Terjadi kegagalan proses ambil data pastikan koneksi internet anda normal atau segera hubungi admin");
      yield put(getNotificationsFail(err));
    }
  }

  // Individual exports for testing
  export default function* notificationScreenSaga() {
    yield all([
      takeLatest(constants.GET_NOTIFICATIONS, doGetNotifications),
    ]);
  }
  