/*
 *
 * WastePickupFormManifestScreenReducer reducer
 *
 */
import {produce} from 'immer';
import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';
import helper from '../../util/helper';

export const initialState = {
    error: 0,
    error_msg: '',
    loading: false,
    description: '',
    notes: '',
    id_pelaporan: 0,
    id_aset: 0,
    nm_aset: "",
    nm_ruangan: "",
    id_branch: 0,
    id_item: 0,
    assets:[],
    code: '',
    no_kendaraan: '',
    tujuan: '',
    code_manifest: '',
    catatan: '',
    dtAmbilLimbah: null,
    dtApproval: null,
    dtManifest: null,
    manifest_name: null,
    manifest_file: null,
};

/* eslint-disable default-case, no-param-reassign */
const WastePickupFormManifestScreenReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
      console.log("reset state");
      return {...initialState};
      case constants.UPDATE_INPUT:
        draft[action.name] = action.value;
        break;
      case constants.SUBMIT_PENGAMBILAN_UNIT:
        draft.loading = true;
        draft.id_branch = action.id_branch;
        break;
      case constants.SUBMIT_PENGAMBILAN_UNIT_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.error_msg= 'Maaf, Hasil pencarian belum ditemukan.';
        break;
      case constants.SUBMIT_PENGAMBILAN_UNIT_SUCCESS:
        draft.id_pelaporan = action.results.id_pelaporan;
        draft.loading = false;
        draft.error = false;
        draft.description = "";
        draft.notes = "";
        draft.assets = [];
        break;
      case constants.SET_MANIFEST:{
        const obj_manifest = action.manifest;
        draft.manifest_file = obj_manifest.base64;
        draft.manifest_name = obj_manifest.name;
      }
        break;
      case constants.DEL_MANIFEST:
        draft.manifest_name = null;
        draft.manifest_file = null;
        break;
      case constants.GET_PENGAMBILAN_UNIT_DETAIL:
        draft.loading = true;
        break;
      case constants.GET_PENGAMBILAN_UNIT_DETAIL_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.error_msg= 'Maaf, Gagal mengambil data pelaporan.';
        draft.results = [];
        break;
      case constants.GET_PENGAMBILAN_UNIT_DETAIL_SUCCESS:{
        draft.dtManifest = action.dtManifest;

        if(!helper.isEmpty(draft.dtManifest)){

          const rawManifest = action.dtManifest;
          draft.code_manifest = rawManifest.code;
          draft.manifest_name = `FILE ${rawManifest.code}`;
          draft.manifest_file = `${rawManifest.manifest_attech?.path}`;
        }

        draft.loading = false;
        }
        break;
    }
  });

export default WastePickupFormManifestScreenReducer;
