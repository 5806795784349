import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
 
  import * as constants from './constants';
  import moment from 'moment';
  import api from '../../util/api';
  import helper from '../../util/helper';
  
  import makeSelectUnitRequestScreen from './selector';
import { delUnitRequestFail, delUnitRequestSuccess, getMoreUnitRequestFail, getMoreUnitRequestSuccess, getMoreWOReportFail, getMoreWOReportSuccess, getUnitRequestFail, getUnitRequestSuccess, getWorkOrderReportFail, getWorkOrderReportSuccess } from './actions';
  
  export function* doGetListUnitRequest(action) {
    const dataset = yield select(makeSelectUnitRequestScreen());
    try {
      const tglAwal = moment(dataset.start_dt).format("YYYY-MM-DD");
      const tglAkhir = moment(dataset.end_dt).format("YYYY-MM-DD HH:mm:ss");
      
      const params = {
        tgl_awal: tglAwal+' 00:00:00',
        tgl_akhir: tglAkhir,
        search: dataset.q,
        status: dataset.filter_status
      };

      if(helper.isEmpty(dataset.start_dt)) delete params.tgl_awal;
      if(helper.isEmpty(dataset.end_dt)) delete params.tgl_akhir;
      if(helper.isEmpty(dataset.q)) delete params.search;
      if(helper.isEmpty(dataset.filter_status)) delete params.status;

      const response = yield call(api.unit.getRequestList, params, dataset.page);
      const {success} = response.data;
      console.log("res:", success);
      if(dataset.page == 1){
        yield put(
          getUnitRequestSuccess((helper.isEmpty(success) ? [] : success)),
        );
      } else {
        yield put(
          getMoreUnitRequestSuccess((helper.isEmpty(success) ? [] : success)),
        );
      }
    } catch (err) {
      console.log(err);
      helper.errorToast("Terjadi kegagalan proses ambil data pastikan koneksi internet anda normal atau segera hubungi admin");
      if(dataset.page == 1){
        yield put(getUnitRequestFail(err));
      } else {
        yield put(getMoreUnitRequestFail(err));
      }
    }
  }


  export function* doDeleteListUnitRequest(action) {
    const {id_permintaan} = action;
    const dataset = yield select(makeSelectUnitRequestScreen());
    try {

      const params = {
        id_permintaan_unit: id_permintaan,
      };

      const response = yield call(api.unit.deleteRequest, params);
      const {success} = response.data;
      console.log("res:", success);
      helper.successToast("Permintaan berhasil dihapus");
      yield put(
        delUnitRequestSuccess((helper.isEmpty(success) ? [] : success)),
      );
    } catch (err) {
      console.log(err);
      helper.errorToast("Proses hapus data tidak dapat dilakukan pastikan data anda belum di proses pada transaksi lebih lanjut atau silahkan hubungi admin");
      yield put(delUnitRequestFail(err));
    }
  }

  // Individual exports for testing
  export default function* vendorProgressScreenSaga() {
    yield all([
      takeLatest(constants.GET_UNIT_REQUEST, doGetListUnitRequest),
      takeLatest(constants.GET_MORE_UNIT_REQUEST, doGetListUnitRequest),
      takeLatest(constants.DELETE_UNIT_REQUEST, doDeleteListUnitRequest),
      takeLatest(constants.DELETE_UNIT_REQUEST_SUCCESS, doGetListUnitRequest)
    ]);
  }
  