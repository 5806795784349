import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectWastePickupFormScreen from './selector';
import reducer from './reducer';
import saga from './saga';

// import {ScrollView,RefreshControl, BackHandler, PermissionsAndroid, StyleSheet, View, Dimensions } from 'react-native';
import {
  AspectRatio,
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Text,
  Pressable,
  TextArea,
  FormControl,
  Divider,
  Image,
  Modal,
  Flex,
  ScrollView, 
  Button,
  Select,
  CheckIcon,
  Stack,
  Menu,
  Fab,
  Input} from 'native-base';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import Files from 'react-files';
import 'react-html5-camera-photo/build/css/index.css';
import {FaCalendarAlt, FaCheckCircle} from 'react-icons/fa';
import {MdAddAPhoto, MdDelete, MdDownload, MdFileCopy, MdModeEdit, MdMoreVert, MdRemoveRedEye} from 'react-icons/md';
import { ContainerPage } from '../../components/ContainerPage';
import { commonStyle } from '../../styles';
import { addImage, defaultAction, delImage, delManifest, getPelaporan, getPengambilanUnitDetail, saveDetail, setManifest, submitPelaporan, submitPengambilanUnit, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';
import BadgeStatus from '../../components/BadgeStatus';
import ImageSlider from '../../components/ImageSlider';
import { AiOutlinePlus } from 'react-icons/ai';
import history from '../../util/history';
import LoadingOverlay from 'react-loading-overlay';

export function WastePickupFormScreen({
  dispatch,
  location,
  navigation,
  wastePickupFormScreen,
  onUpdateInput,
  onAddManifest,
  onDelManifest,
  onSubmitPengambilanUnit,
  onGetDetail,
  onSaveDetail
}){
  const {id_pengambilan_limbah, id_approval, jns_approval_code, new_data, viewOnly} = location.state;
  const[userData, setUserData] = useState();
  const [photo, setPhoto] = useState(null);
  const [openCamera, setOpenCamera] = useState(false);
  const [openModalCamera, setOpenModalCamera] = useState(false);

  const imageList = [
    {path: '../../images/pintu.jpg'},
    {path: '../../images/pintu.jpg'},
    {path: '../../images/pintu.jpg'},
  ];

  const status_detail = {
    '10': 'created',
    '20': 'batal',
    '30': 'approve',
    '40': 'done'
  };

  useEffect(() => {
    // reset params
    dispatch(defaultAction());

    onUpdateInput('id_pengambilan_limbah', id_pengambilan_limbah);
    onUpdateInput('id_approval', id_approval);
    onUpdateInput('jns_approval_code', jns_approval_code);

    //EDIT STATE
    // if(!helper.isEmpty(id_pengambilan_limbah)){
    //   const curdataReq = api.getFromLocalDB('unitReq');
    //   curdataReq.then((val) => {
    //     console.log('saved_pengambilan', val);
    //     if(helper.isEmpty(val) || id_pengambilan_limbah != val?.id_pengambilan_limbah){
    //       // get data if localDB is empty
    //       // onGetPermintaan(id_pengambilan_limbah);
    //     } else {
    //       // get data from localDB
    //       onUpdateInput('no_kendaraan', val.no_kendaraan);
    //       onUpdateInput('tujuan', val.tujuan);
    //       onUpdateInput('code_manifest', val.code_manifest);
    //       onUpdateInput('notes', val.notes);
    //       onUpdateInput('manifest_name', val.manifest_name);
    //       onUpdateInput('manifest_file', val.manifest_file);
    //     }
    //   }).catch(err => {
    //     console.log('err-localdb:', err)
    //   });

    // //ADD STATE
    // } else {
      console.log('new_data', new_data)
      if(new_data){
        // clear for fresh data
        api.clearItemLocalDB('wastePickup');
      } else {
        // get data from localDB
        const curdataReq = api.getFromLocalDB('wastePickup');
        curdataReq.then((val) => {
            // get data from localDB
            onUpdateInput('no_kendaraan', val.no_kendaraan);
            onUpdateInput('tujuan', val.tujuan);
            onUpdateInput('code_manifest', val.code_manifest);
            onUpdateInput('notes', val.notes);
            onUpdateInput('manifest_name', val.manifest_name);
            onUpdateInput('manifest_file', val.manifest_file);
        }).catch(err => {
          console.log('err-localdb:', err)
        });
      } 
      
    //}
    
    onGetDetail();
    console.log("id_l", id_pengambilan_limbah);
  },[]);
  

  const handleChoosePhoto = async (data) => {
    setOpenCamera(false);
    console.log("img:", data);
    setTimeout(() => {
      setOpenModalCamera(false);
    }, 300);
    if(wastePickupFormScreen.assets.length == 4){
      helper.infoToast("Anda melebihi batas maksimum yakni 4 foto.");
      return;
    }

    try {
      if(!helper.isEmpty(data)) onAddManifest(data);
      // const granted = await PermissionsAndroid.request(
      //   PermissionsAndroid.PERMISSIONS.CAMERA,
      //   {
      //     title: "App Camera Permission",
      //     message:"App needs access to your camera",
      //     buttonNeutral: "Ask Me Later",
      //     buttonNegative: "Cancel",
      //     buttonPositive: "OK"
      //   }
      // );
      // if (granted === PermissionsAndroid.RESULTS.GRANTED) {
      //   console.log("Camera permission given");
      //   launchCamera({ 
      //     mediaType: 'photo',
      //     maxWidth: 800,
      //     maxHeight: 800,
      //     includeBase64: true,
      //     cameraType: 'back'
      //   }, (response) => {
      //     console.log(response);
      //     if (response) {
      //       if(!helper.isEmpty(response.assets)) onAddManifest(response.assets[0]);
      //     }
      //   });
      // } else {
      //   console.log("Camera permission denied");
      // }
    } catch (err) {
      console.warn(err);
    }
  };

  const handleSubmit = () => {
    if(helper.isEmpty(wastePickupFormScreen.description) || wastePickupFormScreen.description.length < 10){
      helper.warningToast("Isikan deskripsi kerusakan, Minimal 10 karakter.");
      return;
    }
    if(wastePickupFormScreen.assets.length == 0){
      helper.warningToast("Bukti foto diperlukan min 1 foto.");
      return;
    }

    console.log('desc:',wastePickupFormScreen.description.length);

    onSubmitPengambilanUnit();
  }

  const handleGetData = () => {
    if(id_pengambilan_limbah > 0){
      onGetDetail();
    }
  }

  const handleCameraOpen = () => {
    setOpenModalCamera(true);
    setTimeout(() => {
      setOpenCamera(true);
    }, 300);
  }

  function handleTakePhotoAnimationDone (dataUri) {
    // Do stuff with the photo...
    // console.log('takePhoto');
  }

  function handleCameraError (error) {
    // console.log('handleCameraError', error);
  }

  function handleCameraStart (stream) {
    // console.log('handleCameraStart : ', stream);
  }

  function handleCameraStop () {
    // console.log('handleCameraStop');
  }

  const getBase64 = file => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };

  const handleFileChange = (files) => {
    console.log(files);
    const file = files[0];
    getBase64(file)
      .then(result => {
        file["base64"] = result;
        console.log("File Is", file);
        const params = {
          base64: result,
          type: file.type,
          name: file.name,
        }

        onAddManifest(params);
      })
      .catch(err => {
        console.log(err);
      });
  }

  const handleFileError = (error, file) => {
    console.log('error code ' + error.code + ': ' + error.message)
    helper.errorToast(error.message);
  }

  const renderAccordionDetail = (row, idx) => {
    // console.log('kepanggil:', wastePickupFormScreen.dtAmbilLimbah);
    
    let showApprovalKasie = false;
    let dtAmbilLimbahDetail = null;
    if(wastePickupFormScreen.dtAmbilLimbah.length == 0){
      showApprovalKasie = true;
    } else {
      const idxTrfLimbah = wastePickupFormScreen.dtAmbilLimbah?.pengambilan_limbah_detail.findIndex(
        result => result.id_approval_detail === row.id,
      );
      if(idxTrfLimbah < 0){
        showApprovalKasie = true;
      } else {
        dtAmbilLimbahDetail = wastePickupFormScreen.dtAmbilLimbah?.pengambilan_limbah_detail[idxTrfLimbah];
      }
    }

    return (
        <>
        {showApprovalKasie &&
          <AccordionItem key={idx} header={
            <Box px={5} py={3} backgroundColor={commonStyle.cardBaseHeaderBg}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Box>
                <Icon color="darkText" as={<Box><MdFileCopy/></Box>} size={5}></Icon>
              </Box>
              <Box flex={1} ml={1} alignItems="start">
                <Text color="darkText" fontWeight={500} >
                  Detail Limbah
                </Text>
              </Box>
              <Box ml={3}>
                <BadgeStatus status={row.status ? status_detail[row.status]: status_detail['10']}/>
              </Box>
              <Box ml={2} alignItems="center" justifyContent="center" width={8} height={8}>
                <Menu mt={2} py={2} placement="bottom right" trigger={triggerProps => {
                  return <Pressable accessibilityLabel="More options menu" {...triggerProps}>
                          <Icon as={<Box><MdMoreVert/></Box>} size={8} color="gray.600" />
                        </Pressable>;
                }}>
                  {/* {access.includes('05:VIEW') &&  */}
                  <Menu.Item p={1} onPress={() => handleSubmitFormDetail(row, 'view')}>
                    <HStack space={2} justifyContent="center" alignItems="center">
                      <Icon as={<Box><MdRemoveRedEye/></Box>} size="md" color="info.700" />
                      <Text color="darkText" fontSize="md">
                        View
                      </Text>
                    </HStack>
                  </Menu.Item>
                  {/* } */}
                  {/* {access.includes('05:EDIT') &&  */}
                  <Menu.Item p={1} onPress={() => handleSubmitFormDetail(row, 'edit')}>
                    <HStack space={2} justifyContent="center" alignItems="center">
                      <Icon as={<Box><MdModeEdit/></Box>} size="md" color="info.700" />
                      <Text color="darkText" fontSize="md">
                        Update Penerimaan
                      </Text>
                    </HStack>
                  </Menu.Item>
                  {/* } */}
                </Menu>
              </Box>
            </Stack>
          </Box>
          } initialEntered>
            <VStack backgroundColor="white" p={3}>
              <FormControl mb="1">
                <FormControl.Label>Jenis Limbah</FormControl.Label>
                <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                  <Text textAlign="left">{row.approval_request?.ms_jns_limbah?.nama}</Text>
                </Box>
              </FormControl>
              <FormControl mb="1">
                <FormControl.Label>Volume (L)</FormControl.Label>
                <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                  <Text textAlign="right">{row.approval_request?.volume}</Text>
                </Box>
              </FormControl>
              <FormControl mb="1">
                <FormControl.Label>Berat (kg)</FormControl.Label>
                <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                  <Text textAlign="right">{row.approval_request?.berat}</Text>
                </Box>
              </FormControl>
              <FormControl mb="1">
                <FormControl.Label>Noted</FormControl.Label>
                <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                  <Text>{row.noted}</Text>
                </Box>
              </FormControl>
            </VStack>
          </AccordionItem>
        }
        {(!showApprovalKasie && dtAmbilLimbahDetail) &&
          <AccordionItem key={idx} header={
            <Box px={5} py={3} backgroundColor={commonStyle.cardBaseHeaderBg}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Box>
                <Icon color="darkText" as={<Box><MdFileCopy/></Box>} size={5}></Icon>
              </Box>
              <Box flex={1} ml={1} alignItems="start">
                <Text color="darkText" fontWeight={500} >
                  Detail Limbah
                </Text>
              </Box>
              <Box ml={3}>
                <BadgeStatus status={dtAmbilLimbahDetail.status ? status_detail[dtAmbilLimbahDetail.status]: status_detail['10']}/>
              </Box>
              {/* <Box ml={2} alignItems="center" justifyContent="center" width={8} height={8}>
                <Menu mt={2} py={2} placement="bottom right" trigger={triggerProps => {
                  return <Pressable accessibilityLabel="More options menu" {...triggerProps}>
                          <Icon as={<Box><MdMoreVert/></Box>} size={8} color="gray.600" />
                        </Pressable>;
                }}>
                  <Menu.Item p={1} onPress={() => handleSubmitFormDetail(row, 'view')}>
                    <HStack space={2} justifyContent="center" alignItems="center">
                      <Icon as={<Box><MdRemoveRedEye/></Box>} size="md" color="info.700" />
                      <Text color="darkText" fontSize="md">
                        View
                      </Text>
                    </HStack>
                  </Menu.Item>
                  <Menu.Item p={1} onPress={() => handleSubmitFormDetail(row, 'edit')}>
                    <HStack space={2} justifyContent="center" alignItems="center">
                      <Icon as={<Box><MdModeEdit/></Box>} size="md" color="info.700" />
                      <Text color="darkText" fontSize="md">
                        Update Penerimaan
                      </Text>
                    </HStack>
                  </Menu.Item>
                </Menu>
              </Box> */}
            </Stack>
          </Box>
          } initialEntered>
            <VStack backgroundColor="white" p={3}>
              <Flex m={3}>
                <ImageSlider key={idx} images={dtAmbilLimbahDetail.pengambilan_limbah_attach}/>
              </Flex>
              <FormControl mb="1">
                <FormControl.Label>Jenis Limbah</FormControl.Label>
                <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                  <Text textAlign="left">{dtAmbilLimbahDetail.ms_jns_limbah?.nama}</Text>
                </Box>
              </FormControl>
              <FormControl mb="1">
                <FormControl.Label>Volume (L)</FormControl.Label>
                <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                  <Text textAlign="right">{dtAmbilLimbahDetail.volume}</Text>
                </Box>
              </FormControl>
              <FormControl mb="1">
                <FormControl.Label>Berat (kg)</FormControl.Label>
                <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                  <Text textAlign="right">{dtAmbilLimbahDetail.berat}</Text>
                </Box>
              </FormControl>
              <FormControl mb="1">
                <FormControl.Label>Noted</FormControl.Label>
                <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                  <Text>{dtAmbilLimbahDetail.noted}</Text>
                </Box>
              </FormControl>
            </VStack>
          </AccordionItem>
        }
        </>
    );
  }

  const handleSubmitFormDetail = (data, act) => {
    const check_param = {
        no_kendaraan: wastePickupFormScreen.no_kendaraan,
        tujuan: wastePickupFormScreen.tujuan
        // code_manifest: wastePickupFormScreen.code_manifest,
        // manifest_name: wastePickupFormScreen.manifest_name,
        // manifest_file: wastePickupFormScreen.manifest_file,
    };

    const isAllFilled = (obj) => {
        return Object.values(obj).every(value => value !== "" && value !== null && value !== undefined);
    };
    
    if (!isAllFilled(check_param)) {
        helper.warningToast('Mohon lengkapi semua data!');
        return;
    }

    onSaveDetail();
    const params = {
      id_pengambilan_limbah: wastePickupFormScreen.id_pengambilan_limbah,
      id_approval: wastePickupFormScreen.id_approval,
      jns_approval_code: wastePickupFormScreen.jns_approval_code,
      id_approval_request: data.id_approval_request,
      id_approval_kasie: data.id,
    };

    history.push('waste-pickup-form-detail', {...params, readOnly: (act == 'view')});
  } 

  return (
        <ContainerPage options={{statusBar: true, prevPage: 'waste-pickup', title: 'Pengambilan Limbah', params:{}}}>
          <Flex flex={1} minW="300px">
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
              <LoadingOverlay
                active={wastePickupFormScreen.loading}
                spinner
                text='Loading...'
                >
              <VStack space={7} mt={2} mb={35} mx={3}>
                {wastePickupFormScreen.code && 
                <Box mt={5}>
                  <Center>
                    <HStack space={2} justifyContent="center" alignItems="center">
                      <Icon as={<Box><FaCheckCircle/></Box>} size="8" color="success.700" />
                      <Text color="darkText" fontWeight="bold" isTruncated maxWidth="90%" fontSize="lg">
                        {wastePickupFormScreen.code}
                      </Text>
                    </HStack>
                  </Center>
                </Box>
                }
                <Box p={5} borderRadius="lg" shadow={3} backgroundColor="white">
                    <VStack>
                      <Box mb={2}>
                      <FormControl mb="1" isRequired>
                          <FormControl.Label>No Kendaraan</FormControl.Label>
                          <Input borderRadius="lg" value={wastePickupFormScreen.no_kendaraan} onChange={evt => onUpdateInput('no_kendaraan', evt.currentTarget.value)} />
                        </FormControl>
                        <FormControl mb="1" isRequired>
                          <FormControl.Label>Perusahaan Tujuan</FormControl.Label>
                          <Input borderRadius="lg" value={wastePickupFormScreen.tujuan} onChange={evt => onUpdateInput('tujuan', evt.currentTarget.value)} />
                        </FormControl>
                        <FormControl mb="1" isRequired>
                          <FormControl.Label>Code Manifest</FormControl.Label>
                          <Input borderRadius="lg" value={wastePickupFormScreen.code_manifest} onChange={evt => onUpdateInput('code_manifest', evt.currentTarget.value)} />
                        </FormControl>
                        <FormControl mb="1" isRequired>
                          <FormControl.Label>Upload Manifest</FormControl.Label>
                              {!wastePickupFormScreen.manifest_name && 
                                <Box justifyContent="center" alignItems="center" p={2} borderColor="muted.400" borderRadius="lg" borderWidth={1} borderStyle="dashed">
                                  <Files
                                    className='files-dropzone'
                                    onChange={handleFileChange}
                                    onError={handleFileError}
                                    accepts={['image/png', 'image/jpg','image/jpeg', '.pdf']}
                                    maxFileSize={(10000000 * 20)} // 20 MB
                                    minFileSize={0}
                                    clickable>
                                    Drop files here or click to upload
                                  </Files>
                                </Box>
                              }
                              {wastePickupFormScreen.manifest_name &&
                                <HStack justifyContent="center" alignItems="center">
                                  <Flex flex={1} flexDirection="column">
                                    <Text fontSize="sm" fontWeight="bold">{wastePickupFormScreen.manifest_name}</Text>
                                  </Flex>
                                  <Box>
                                    {!viewOnly &&
                                      <Pressable onPress={() => onDelManifest()}>
                                        <Icon as={<Box><MdDelete/></Box>} color="red.500" size="lg"></Icon>
                                      </Pressable>
                                    }
                                  </Box>
                                </HStack>
                              }
                        </FormControl>
                      </Box>
                      <Box mb={2}>
                        <FormControl mb="1">
                          <FormControl.Label>Catatan</FormControl.Label>
                          <TextArea value={wastePickupFormScreen.notes} onChangeText={txt => onUpdateInput('notes', txt)} h={40} placeholder="Catatan jika dibutuhkan" borderRadius="lg" w="100%" />
                        </FormControl>
                      </Box>
                      
                    </VStack>
                </Box>
                <Box>
                  <Accordion>
                    {wastePickupFormScreen.dtApproval?.approvalkasie_detail.map((row, i) => renderAccordionDetail(row, i))}
                  </Accordion>
                </Box>
                {/* <Box>
                  <Accordion>
                  {[...Array(3)].map((x, i) => (
                    <AccordionItem key={i} header={
                      <Box px={5} py={3} backgroundColor={commonStyle.cardBaseHeaderBg}>
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Box>
                          <Icon color="darkText" as={<Box><MdFileCopy/></Box>} size={5}></Icon>
                        </Box>
                        <Box flex={1} ml={1} alignItems="start">
                          <Text color="darkText" fontWeight={500} >
                            Detail Limbah
                          </Text>
                        </Box>
                        <Box ml={3}>
                          <BadgeStatus status="Created"/>
                        </Box>
                        <Box ml={2} alignItems="center" justifyContent="center" width={8} height={8}>
                          <Menu mt={2} py={2} placement="bottom right" trigger={triggerProps => {
                            return <Pressable accessibilityLabel="More options menu" {...triggerProps}>
                                    <Icon as={<Box><MdMoreVert/></Box>} size={8} color="gray.600" />
                                  </Pressable>;
                          }}>
                            <Menu.Item p={1} onPress={() => {}}>
                              <HStack space={2} justifyContent="center" alignItems="center">
                                <Icon as={<Box><MdRemoveRedEye/></Box>} size="md" color="info.700" />
                                <Text color="darkText" fontSize="md">
                                  View
                                </Text>
                              </HStack>
                            </Menu.Item>
                            <Menu.Item p={1} onPress={() => history.push('waste-pickup-form-detail')}>
                              <HStack space={2} justifyContent="center" alignItems="center">
                                <Icon as={<Box><MdModeEdit/></Box>} size="md" color="info.700" />
                                <Text color="darkText" fontSize="md">
                                  Update Penerimaan
                                </Text>
                              </HStack>
                            </Menu.Item>
                          </Menu>
                        </Box>
                      </Stack>
                    </Box>
                    } initialEntered>
                      <Flex m={3}>
                        <ImageSlider key={i} images={imageList}/>
                      </Flex>
                      <VStack backgroundColor="white" p={3}>
                        <FormControl mb="1">
                          <FormControl.Label>Jenis Limbah</FormControl.Label>
                          <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                            <Text textAlign="left">Cair</Text>
                          </Box>
                        </FormControl>
                        <FormControl mb="1">
                          <FormControl.Label>Volume (L)</FormControl.Label>
                          <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                            <Text textAlign="right">60</Text>
                          </Box>
                        </FormControl>
                        <FormControl mb="1">
                          <FormControl.Label>Berat (kg)</FormControl.Label>
                          <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                            <Text textAlign="right">80</Text>
                          </Box>
                        </FormControl>
                        <FormControl mb="1">
                          <FormControl.Label>Noted</FormControl.Label>
                          <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                            <Text>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s</Text>
                          </Box>
                        </FormControl>
                      </VStack>
                    </AccordionItem>
                  ))}
                  </Accordion>
                </Box> */}
                {/* {!viewOnly &&
                <HStack justifyContent="space-around" mb="32">
                    <Button w="1/3" isLoading={wastePickupFormScreen.loading} spinnerPlacement="end" isLoadingText="Submitting..." colorScheme="primary" onPress={() => handleSubmit()}>Kirim</Button>
                    <Button variant="link" colorScheme="primary">Cancel</Button>
                </HStack>
                } */}
              </VStack>
              </LoadingOverlay>
            </ScrollView>
          </Flex>
        </ContainerPage>
      );
    
}

const mapStateToProps = createStructuredSelector({
  wastePickupFormScreen: makeSelectWastePickupFormScreen(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onAddManifest: manifest => dispatch(setManifest(manifest)),
    onDelManifest: () => dispatch(delManifest()),
    onSubmitPengambilanUnit: () => dispatch(submitPengambilanUnit()),
    onGetDetail: () => dispatch(getPengambilanUnitDetail()),
    onSaveDetail: () => dispatch(saveDetail())
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(WastePickupFormScreen);