import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
 
  import * as constants from './constants';
  import moment from 'moment';
  import api from '../../util/api';
  import helper from '../../util/helper';
  
  import makeSelectManifestScreen from './selector';
  import { getManifestFail, getManifestSuccess, getMoreManifestFail, getMoreManifestSuccess, getMoreWOReportFail, getMoreWOReportSuccess, getWorkOrderReportFail, getWorkOrderReportSuccess } from './actions';
  
  export function* doGetListManifest(action) {
    const dataset = yield select(makeSelectManifestScreen());
    try {
      const tglAwal = moment(dataset.start_dt).format("YYYY-MM-DD");
      const tglAkhir = moment(dataset.end_dt).format("YYYY-MM-DD HH:mm:ss");
      
      const params = {
        tgl_awal: tglAwal+' 00:00:00',
        tgl_akhir: tglAkhir,
        search: dataset.q,
        status: dataset.filter_status
      };

      if(helper.isEmpty(dataset.start_dt)) delete params.date_start;
      if(helper.isEmpty(dataset.end_dt)) delete params.date_end;
      if(helper.isEmpty(dataset.q)) delete params.search;
      if(helper.isEmpty(dataset.filter_status)) delete params.status;

      const response = yield call(api.manifest.getManifestList, params, dataset.page);
      const {success} = response.data;
      console.log("res:", success);
      if(dataset.page == 1){
        yield put(
          getManifestSuccess((helper.isEmpty(success) ? [] : success)),
        );
      } else {
        yield put(
          getMoreManifestSuccess((helper.isEmpty(success) ? [] : success)),
        );
      }
    } catch (err) {
      console.log(err);
      helper.errorToast("Terjadi kegagalan proses ambil data pastikan koneksi internet anda normal atau segera hubungi admin");
      if(dataset.page == 1){
        yield put(getManifestFail(err));
      } else {
        yield put(getMoreManifestFail(err));
      }
    }
  }

  // Individual exports for testing
  export default function* manifestScreenSaga() {
    yield all([
      takeLatest(constants.GET_MANIFEST, doGetListManifest),
      takeLatest(constants.GET_MORE_MANIFEST, doGetListManifest),
    ]);
  }
  