import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
import api from '../../util/api';
import helper from '../../util/helper';
import history from '../../util/history';
// import * as RootNavigation from '../../../RootNavigation';
import { getApprovalDetailFail, getApprovalDetailSuccess, getListUnitFail, getListUnitSuccess, getPelaporanFail, getPelaporanSuccess, submitApprovalFail, submitApprovalSuccess, submitPelaporanFail, submitPelaporanSuccess } from './actions';

  import * as constants from './constants';
  
  import makeSelectApprovalFormScreen from './selector';
import moment from 'moment';
  
  export function* doSubmitApproval(action) {
    const {id_branch, origin_screen} = action;
    const dataset = yield select(makeSelectApprovalFormScreen());
    try {

      const {id_pegawai, id_unit ,id_unit_tujuan, id_lokasi_tujuan} = dataset;
      if (!id_pegawai || !id_unit || !id_unit_tujuan || !id_lokasi_tujuan) {
        const err = "Lengkapi semua inputan terlebih dahulu!";
        helper.warningToast(err);
        yield put(submitApprovalFail(err));
        return;
      }


      let details = [];
      dataset.approval_details.map((raw, idx) => {
        details.push({
          id_approval_request: raw.id_app_request,
          id_approval_request_header: dataset.dtRequest?.id,
          id_approval_request_detail: raw.id
        });
      });

      const params = {
          id_pegawai_lapangan: dataset.id_pegawai,
          id_unit: dataset.id_unit,
          id_unit_tujuan: dataset.id_unit_tujuan,
          id_lokasi_asal: dataset.id_lokasi,
          id_lokasi_tujuan: dataset.id_lokasi_tujuan,
          id_vendor: dataset.id_vendor,
          id: dataset.id_approval,
          tgl_penugasan: moment().format("YYYY-MM-DD HH:mm:ss"),
          noted: dataset.notes,
          approval_detail: JSON.stringify(details),
      };

      if(helper.isEmpty(params.noted)) delete params.noted;

      if(dataset.jns_approval_code == '1') delete params.id_vendor;

      // console.log(params);

      const response = yield call(api.approval.submitApproval, params);
      const {success} = response.data;

      yield put(
        submitApprovalSuccess(success),
      );
      helper.successToast("Data berhasil disimpan");
      console.log("res:", success);
      history.replace('approval'); 
    } catch (err) {
      console.log(err);
      helper.errorToast("Transaksi tidak dapat disimpan pastikan detail berikut terisi :\n\r1. Lokasi tujuan wajib terisi\n\r2. Unit Tujuan wajib terisi\n\r3. Petugas lapangan wajib terisi\n\r4. Jika tipe approval 6 vendor wajib terisi");
      yield put(submitApprovalFail(err));
    }
  }

  export function* doGetApprovalDetail(action) {
    const dataset = yield select(makeSelectApprovalFormScreen());
    try {
      const params = {
        id_approval: dataset.id_approval,
        id_approval_request: dataset.id_approval_request,
        jns_approval_code: dataset.jns_approval_code
      };

      const response = yield call(api.approval.getApprovalDetail, params);
      const {success} = response.data;
  
      yield put(
        getApprovalDetailSuccess(success.dtApproval, success.dtRequest),
      );
    } catch (err) {
      console.log(err);
      helper.errorToast("Terjadi kegagalan proses ambil data pastikan koneksi internet anda normal atau segera hubungi admin");
      yield put(getApprovalDetailFail(err));
    }
  }

  export function* doGetAllUnit(action) {

    const {jns_approval_code} = action;

    try {
      const params = {
        jns_approval_code: jns_approval_code,
      };

      const response = yield call(api.master.getAllUnits, params);
      const {data} = response.data;
      console.log('unit_list:', data);
      yield put(
        getListUnitSuccess(data),
      );
      
      // RootNavigation.navigate('page', {screen: 'home'});

    } catch (err) {
      console.log(err);
      helper.errorToast("Terjadi kegagalan proses ambil data pastikan koneksi internet anda normal atau segera hubungi admin");
      yield put(getListUnitFail(err));
    }
  }

  // Individual exports for testing
  export default function* unitRequestFormScreenSaga() {
    yield all([
      takeLatest(constants.SUBMIT_APPROVAL, doSubmitApproval),
      takeLatest(constants.GET_APPROVAL_DETAIL, doGetApprovalDetail),
      takeLatest(constants.GET_LIST_UNIT, doGetAllUnit),
    ]);
  }
  