/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import history from './util/history';
// reducers
import GlobalVarReducer from './util/reducer';
import LoginScreenReducer from './screens/LoginScreen/reducer';
import ForgetPassScreenReducer from './screens/ForgetPassScreen/reducer';
import HomeScreenReducer from './screens/HomeScreen/reducer';
import ProfileScreenReducer from './screens/ProfileScreen/reducer';
import WelcomeScreenReducer from './screens/WelcomeScreen/reducer';
import FormProfileScreenReducer from './screens/FormProfileScreen/reducer';
import FormChangePassScreenReducer from './screens/FormChangePassScreen/reducer';
import NotificationScreenReducer from './screens/NotificationScreen/reducer';
import NotificationDetailScreenReducer from './screens/NotificationDetailScreen/reducer';

import UnitRequestScreenReducer from './screens/UnitRequestScreen/reducer';
import UnitRequestFormScreenReducer from './screens/UnitRequestFormScreen/reducer';
import UnitRequestFormDetailScreenReducer from './screens/UnitRequestFormDetailScreen/reducer';

import WasteTransferScreenReducer from './screens/WasteTransferScreen/reducer';
import WasteTransferFormScreenReducer from './screens/WasteTransferFormScreen/reducer';
import WasteTransferFormDetailScreenReducer from './screens/WasteTransferFormDetailScreen/reducer';

import WastePickupScreenReducer from './screens/WastePickupScreen/reducer';
import WastePickupFormScreenReducer from './screens/WastePickupFormScreen/reducer';
import WastePickupFormDetailScreenReducer from './screens/WastePickupFormDetailScreen/reducer';
import WastePickupFormManifestScreenReducer from './screens/WastePickupFormManifestScreen/reducer';

import WasteAcceptanceScreenReducer from './screens/WasteAcceptanceScreen/reducer';
import WasteAcceptanceFormScreenReducer from './screens/WasteAcceptanceFormScreen/reducer';
import WasteAcceptanceFormDetailScreenReducer from './screens/WasteAcceptanceFormDetailScreen/reducer';

import PostAnnounceScreenReducer from './screens/PostAnnounceScreen/reducer';
import PostAnnounceFormScreenReducer from './screens/PostAnnounceFormScreen/reducer';
import PostAnnounceFormDetailScreenReducer from './screens/PostAnnounceFormDetailScreen/reducer';
import PostAnnounceFormVendorScreenReducer from './screens/PostAnnounceFormVendorScreen/reducer';

import ApprovalScreenReducer from './screens/ApprovalScreen/reducer';
import ApprovalFormScreenReducer from './screens/ApprovalFormScreen/reducer';

import UnitAnnounceScreenReducer from './screens/UnitAnnounceScreen/reducer';
import UnitAnnounceFormScreenReducer from './screens/UnitAnnounceFormScreen/reducer';
import UnitAnnounceFormDetailScreenReducer from './screens/UnitAnnounceFormDetailScreen/reducer';

import ManifestScreenReducer from './screens/ManifestScreen/reducer';
import ManifestDetailScreenReducer from './screens/ManifestDetailScreen/reducer';

import WasteUnitScreenReducer from './screens/WasteUnitScreen/reducer';
// end reducers
/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function combinedReducers() {
const rootReducer = combineReducers({
    router: connectRouter(history),
    globalVar: GlobalVarReducer,
    loginScreen: LoginScreenReducer,
    forgetPassScreen: ForgetPassScreenReducer,
    homeScreen: HomeScreenReducer,
    welcomeScreen: WelcomeScreenReducer,
    profileScreen: ProfileScreenReducer,
    formProfileScreen: FormProfileScreenReducer,
    formChangePassScreen: FormChangePassScreenReducer,
    notificationScreen: NotificationScreenReducer,
    notificationDetailScreen: NotificationDetailScreenReducer,
    
    unitRequestScreen: UnitRequestScreenReducer,
    unitRequestFormScreen: UnitRequestFormScreenReducer,
    unitRequestFormDetailScreen: UnitRequestFormDetailScreenReducer,

    wasteTransferScreen: WasteTransferScreenReducer,
    wasteTransferFormScreen: WasteTransferFormScreenReducer,
    wasteTransferFormDetailScreen: WasteTransferFormDetailScreenReducer,

    wasteAcceptanceScreen: WasteAcceptanceScreenReducer,
    wasteAcceptanceFormScreen: WasteAcceptanceFormScreenReducer,
    wasteAcceptanceFormDetailScreen: WasteAcceptanceFormDetailScreenReducer,

    wastePickupScreen: WastePickupScreenReducer,
    wastePickupFormScreen: WastePickupFormScreenReducer,
    wastePickupFormDetailScreen: WastePickupFormDetailScreenReducer,
    wastePickupFormManifestScreen: WastePickupFormManifestScreenReducer,
    
    postAnnounceScreen: PostAnnounceScreenReducer,
    postAnnounceFormScreen: PostAnnounceFormScreenReducer,
    postAnnounceFormDetailScreen: PostAnnounceFormDetailScreenReducer,
    postAnnounceFormVendorScreen: PostAnnounceFormVendorScreenReducer,

    approvalScreen: ApprovalScreenReducer,
    approvalFormScreen: ApprovalFormScreenReducer,

    unitAnnounceScreen: UnitAnnounceScreenReducer,
    unitAnnounceFormScreen: UnitAnnounceFormScreenReducer,
    unitAnnounceFormDetailScreen: UnitAnnounceFormDetailScreenReducer,

    manifestScreen: ManifestScreenReducer,
    manifestDetailScreen: ManifestDetailScreenReducer,

    wasteUnitScreen: WasteUnitScreenReducer,
});

return rootReducer;
} 