import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
 
  import * as constants from './constants';
  import moment from 'moment';
  import api from '../../util/api';
  import helper from '../../util/helper';
  
  import makeSelectWasteAcceptanceScreen from './selector';
  import { delWasteAcceptFail, delWasteAcceptSuccess, getMorePenerimaanLimbah, getMorePenerimaanLimbahFail, getMorePenerimaanLimbahSuccess, getPenerimaanLimbahSuccess} from './actions';
  
  export function* doGetListPenerimaanLimbah(action) {
    const dataset = yield select(makeSelectWasteAcceptanceScreen());
    try {
      const tglAwal = moment(dataset.start_dt).format("YYYY-MM-DD");
      const tglAkhir = moment(dataset.end_dt).format("YYYY-MM-DD HH:mm:ss");
      
      const params = {
        tgl_awal: tglAwal+' 00:00:00',
        tgl_akhir: tglAkhir,
        search: dataset.q,
        status: dataset.filter_status
      };

      if(helper.isEmpty(dataset.start_dt)) delete params.date_start;
      if(helper.isEmpty(dataset.end_dt)) delete params.date_end;
      if(helper.isEmpty(dataset.q)) delete params.search;
      if(helper.isEmpty(dataset.filter_status)) delete params.status;

      const response = yield call(api.tps.getPenerimaanList, params, dataset.page);
      const {success} = response.data;
      console.log("res:", success);
      if(dataset.page == 1){
        yield put(
          getPenerimaanLimbahSuccess((helper.isEmpty(success) ? [] : success)),
        );
      } else {
        yield put(
          getMorePenerimaanLimbahSuccess((helper.isEmpty(success) ? [] : success)),
        );
      }
    } catch (err) {
      console.log(err);
      helper.errorToast("Terjadi kegagalan proses ambil data pastikan koneksi internet anda normal atau segera hubungi admin");
      if(dataset.page == 1){
        yield put(getPenerimaanLimbahSuccess(err));
      } else {
        yield put(getMorePenerimaanLimbahFail(err));
      }
    }
  }

  export function* doDeleteListWasteAcceptance(action) {
    const {id_penerimaan} = action;
    const dataset = yield select(makeSelectWasteAcceptanceScreen());
    try {

      const params = {
        id_penerimaan_limbah: id_penerimaan,
      };

      const response = yield call(api.tps.deletePenerimaanDetail, params);
      const {success} = response.data;
      console.log("res:", success);
      helper.successToast("Permintaan berhasil dihapus");
      yield put(
        delWasteAcceptSuccess((helper.isEmpty(success) ? [] : success)),
      );
    } catch (err) {
      console.log(err);
      helper.errorToast("Proses hapus data tidak dapat dilakukan pastikan data anda belum di proses pada transaksi lebih lanjut atau silahkan hubungi admin");
      yield put(delWasteAcceptFail(err));
    }
  }

  // Individual exports for testing
  export default function* wasteAcceptanceScreenSaga() {
    yield all([
      takeLatest(constants.GET_PENERIMAAN_LIMBAH, doGetListPenerimaanLimbah),
      takeLatest(constants.GET_MORE_PENERIMAAN_LIMBAH, doGetListPenerimaanLimbah),
      takeLatest(constants.DELETE_PENERIMAAN, doDeleteListWasteAcceptance),
      takeLatest(constants.DELETE_PENERIMAAN_SUCCESS, doGetListPenerimaanLimbah)
    ]);
  }
  