import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
 
  import * as constants from './constants';
  import api from '../../util/api';
  import helper from '../../util/helper';
  import history from '../../util/history';
  // import * as RootNavigation from '../../../RootNavigation';
  
  import makeSelectUnitRequestFormDetailScreen from './selector';
import { getDetailTodoFail, getDetailTodoSuccess, submitDetailFail, submitDetailSuccess, submitPreventifFail, submitPreventifSuccess } from './actions';
  
  export function* doGetPreventifTodo(action) {
    const {id_realisasi_preventif, id_inventaris} = action;
    try {
      const response = yield call(api.preventif.getPreventifTodo, id_realisasi_preventif, id_inventaris);
      yield put(
        getDetailTodoSuccess(response.data),
      );
      console.log("form-res:", response.data);
    } catch (err) {
      console.log(err);
      helper.errorToast("Terjadi kegagalan proses ambil data pastikan koneksi internet anda normal atau segera hubungi admin");
      yield put(getDetailTodoFail(err));
    }
  }

  export function* doSubmitDetail(action) {
    const {idx_detail} = action;
    const dataset = yield select(makeSelectUnitRequestFormDetailScreen());
    try {
      const params = {
        id_jenis_limbah: dataset.id_jenis_limbah,
        code_limbah: dataset.jenis_limbah.code,
        nama_limbah: dataset.jenis_limbah.nama,
        volume: dataset.volume,
        berat: dataset.berat,
        attachment: dataset.assets,
        note: dataset.description
      };

      console.log(idx_detail, params);
      if(helper.isNull(idx_detail)) {
        const cur_details = dataset.saved_details;
        // console.log('saga_cur_details', cur_details)
        // cur_details.push(params);
        api.saveToLocalDB('unitReqDetail', [...cur_details, params]);
      } else {
        const cur_details = dataset.saved_details;
        // console.log('edit_saga_cur_details', cur_details)
        api.saveToLocalDB('unitReqDetail', [...cur_details.slice(0, idx_detail), params, ...cur_details.slice(idx_detail + 1)]);
      }

      yield put(
        submitDetailSuccess(),
      );
      helper.successToast("Data berhasil disimpan");
      console.log("res:", );
      
      setTimeout(() => {
        history.replace('unit-request-form',{id_permintaan: dataset.id_permintaan}); 
      }, 1000);
  
    } catch (err) {
      console.log(err);
      helper.errorToast("Gagal Menyimpan data, silahkan hub admin");
      yield put(submitDetailFail(err));
    }
  }

  // Individual exports for testing
  export default function* unitRequestFormDetailScreenSaga() {
    yield all([
      takeLatest(constants.GET_DETAIL_TODO, doGetPreventifTodo),
      takeLatest(constants.SUBMIT_DETAIL, doSubmitDetail),
    ]);
  }
  