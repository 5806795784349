import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
import api from '../../util/api';
import helper from '../../util/helper';
import history from '../../util/history';
// import * as RootNavigation from '../../../RootNavigation';
import { getPelaporanFail, getPelaporanSuccess, getPenerimaanDetailFail, getPenerimaanDetailSuccess, submitPelaporanFail, submitPelaporanSuccess, submitPenerimaanFail, submitPenerimaanSuccess } from './actions';

  import * as constants from './constants';
  
  import makeSelectWasteAccpetanceFormScreen from './selector';
  
  export function* doSubmitPenerimaan(action) {
    const {id_branch, origin_screen} = action;
    const dataset = yield select(makeSelectWasteAccpetanceFormScreen());
    try {
      const params = {
          id_pelaporan: dataset.id_pelaporan,
          id_ruang: dataset.id_item,
          id_inventaris: dataset.id_aset,
          description: dataset.description,
          notes: dataset.notes,
          attachment: dataset.assets,
      };

      // console.log(params);

      const response = yield call(api.pelaporan.submitPelaporan, params);
      yield put(
        submitPenerimaanSuccess(response.data, dataset.id_aset),
      );
      helper.successToast("Data berhasil disimpan");
      console.log("res:", response.data);

      // save current pelaporan
      // if(origin_screen == 'pelaporan-detail'){
      //   api.saveCurrInputPelaporan(dataset.id_aset, response.data.id_pelaporan);
      //   history.push(origin_screen, {id_branch: id_branch, id_item: dataset.id_item, new_data: false});
      // }else{
      //   history.push(origin_screen);
      // }
      
  
    } catch (err) {
      console.log(err);
      helper.errorToast("Gagal Menyimpan data, silahkan hub admin");
      yield put(submitPenerimaanFail(err));
    }
  }

  export function* doGetPenerimaanDetail(action) {
    const dataset = yield select(makeSelectWasteAccpetanceFormScreen());

    const params = {
      id_transfer: action.id_transfer,
      id_penerimaan: action.id_penerimaan
    };

    try {
      const response = yield call(api.tps.getPenerimaanDetail, params);
      const {success} = response.data;
  
      yield put(
        getPenerimaanDetailSuccess(success?.dtTransfer, success?.dtPenerimaanLimbah),
      );
    } catch (err) {
      console.log(err);
      helper.errorToast("Terjadi kegagalan proses ambil data pastikan koneksi internet anda normal atau segera hubungi admin");
      yield put(getPenerimaanDetailFail(err));
    }
  }

  // Individual exports for testing
  export default function* wasteAcceptanceFormScreenSaga() {
    yield all([
      takeLatest(constants.SUBMIT_PENERIMAAN, doSubmitPenerimaan),
      takeLatest(constants.GET_PENERIMAAN_DETAIl, doGetPenerimaanDetail),
    ]);
  }
  