export const DEFAULT_ACTION = 'screen/PostAnnounceFormVendorScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/PostAnnounceFormVendorScreen/UPDATE_INPUT';

export const GET_PENGUMUMAN = 'screen/PostAnnounceFormVendorScreen/GET_PENGUMUMAN';
export const GET_PENGUMUMAN_LOADING = 'screen/PostAnnounceFormVendorScreen/GET_PENGUMUMAN_LOADING';
export const GET_PENGUMUMAN_SUCCESS = 'screen/PostAnnounceFormVendorScreen/GET_PENGUMUMAN_SUCCESS';
export const GET_PENGUMUMAN_FAIL = 'screen/PostAnnounceFormVendorScreen/GET_PENGUMUMAN_FAIL';

export const SUBMIT_CALL_VENDOR = 'screen/PostAnnounceFormVendorScreen/SUBMIT_CALL_VENDOR';
export const SUBMIT_CALL_VENDOR_LOADING = 'screen/PostAnnounceFormVendorScreen/SUBMIT_CALL_VENDOR_LOADING';
export const SUBMIT_CALL_VENDOR_SUCCESS = 'screen/PostAnnounceFormVendorScreen/SUBMIT_CALL_VENDOR_SUCCESS';
export const SUBMIT_CALL_VENDOR_FAIL = 'screen/PostAnnounceFormVendorScreen/SUBMIT_CALL_VENDOR_FAIL';

export const DEL_SAVED_DETAIL = 'screen/PostAnnounceFormVendorScreen/DEL_SAVED_DETAIL';
export const SET_SAVED_DETAIL = 'screen/PostAnnounceFormVendorScreen/SET_SAVED_DETAIL';