export const DEFAULT_ACTION = 'screen/WastePickupFormManifestScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/WastePickupFormManifestScreen/UPDATE_INPUT';

export const SUBMIT_PENGAMBILAN_UNIT = 'screen/WastePickupFormManifestScreen/SUBMIT_PENGAMBILAN_UNIT';
export const SUBMIT_PENGAMBILAN_UNIT_LOADING = 'screen/WastePickupFormManifestScreen/SUBMIT_PENGAMBILAN_UNIT_LOADING';
export const SUBMIT_PENGAMBILAN_UNIT_SUCCESS = 'screen/WastePickupFormManifestScreen/SUBMIT_PENGAMBILAN_UNIT_SUCCESS';
export const SUBMIT_PENGAMBILAN_UNIT_FAIL = 'screen/WastePickupFormManifestScreen/SUBMIT_PENGAMBILAN_UNIT_FAIL';

export const SET_MANIFEST = 'screen/WastePickupFormManifestScreen/SET_MANIFEST';
export const DEL_MANIFEST = 'screen/WastePickupFormManifestScreen/DEL_MANIFEST';

export const GET_PENGAMBILAN_UNIT_DETAIL = 'screen/WastePickupFormManifestScreen/GET_PENGAMBILAN_UNIT_DETAIL';
export const GET_PENGAMBILAN_UNIT_DETAIL_LOADING = 'screen/WastePickupFormManifestScreen/GET_PENGAMBILAN_UNIT_DETAIL_LOADING';
export const GET_PENGAMBILAN_UNIT_DETAIL_SUCCESS = 'screen/WastePickupFormManifestScreen/GET_PENGAMBILAN_UNIT_DETAIL_SUCCESS';
export const GET_PENGAMBILAN_UNIT_DETAIL_FAIL = 'screen/WastePickupFormManifestScreen/GET_PENGAMBILAN_UNIT_DETAIL_FAIL';

export const SAVE_DETAIL = 'screen/WastePickupFormManifestScreen/SAVE_DETAIL';
