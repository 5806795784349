/*
 *
 * ApprovalFormScreen reducer
 *
 */
import {produce} from 'immer';
import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';
import helper from '../../util/helper';

export const initialState = {
    error: 0,
    error_msg: '',
    loading: false,
    loading_all_unit: false,
    description: '',
    notes: '',
    id_unit:'',
    id_vendor:'',
    id_lokasi_tujuan:'',
    id_unit_tujuan:'',
    id_pegawai:'',
    id_approval: 0,
    id_approval_request: 0,
    jns_approval_code: '',
    id_lokasi: '',
    code: '',
    approval_details: [],
    list_units: [],
    dtRequest: null,
    dtApproval: null,
};

/* eslint-disable default-case, no-param-reassign */
const ApprovalFormScreenReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
      console.log("reset state");
      return {...initialState};
      case constants.UPDATE_INPUT:
        draft[action.name] = action.value;
        break;
      case constants.SUBMIT_APPROVAL:
        draft.loading = true;
        break;
      case constants.SUBMIT_APPROVAL_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.error_msg= 'Maaf, data belum disimpan.';
        break;
      case constants.SUBMIT_APPROVAL_SUCCESS:
        draft.loading = false;
        draft.error = false;
        draft.description = "";
        draft.notes = "";
        draft.assets = [];
        break;
      case constants.ADD_IMAGE:{
        let cur_arr = draft.assets;
        const image = action.asset;
        cur_arr.push({path: image});
        draft.assets = cur_arr;
      }
        break;
      case constants.DEL_IMAGE:
        draft.assets = [...draft.assets.slice(0, action.idx), ...draft.assets.slice(action.idx + 1)];
        break;
      case constants.GET_APPROVAL_DETAIL:
        draft.loading = true;
        break;
      case constants.GET_APPROVAL_DETAIL_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.error_msg= 'Maaf, Gagal mengambil data pelaporan.';
        draft.results = [];
        break;
      case constants.GET_APPROVAL_DETAIL_SUCCESS:
        draft.dtRequest = action.dtRequest;
        draft.dtApproval = action.dtApproval;
        if(helper.isEmpty(draft.dtApproval)){
          draft.id_unit = action.dtRequest?.id_unit;
          draft.id_pegawai = action.dtRequest?.id_pegawai_req;
          draft.id_lokasi_asal = action.dtRequest?.id_lokasi;
          draft.notes = action.dtRequest?.noted;
        }else{
          // draft.id_unit = action.dtApproval?.id_unit_tps;
          // draft.id_unit_tujuan = action.dtApproval?.id_unit_tujuan;
          draft.id_unit = action.dtApproval?.id_unit_tujuan;
          draft.id_unit_tujuan = action.dtApproval?.id_unit_tps;
          draft.id_lokasi_asal = action.dtApproval?.id_lokasi_tps;
          draft.id_pegawai = action.dtApproval?.app_petugas_lapangan?.id_pegawai;
          draft.id_lokasi_tujuan = action.dtApproval?.id_lokasi_tujuan;
          draft.id_vendor = action.dtApproval?.id_vendor;
          draft.notes = action.dtApproval?.noted;
        }

        draft.code = action.dtRequest?.code;
        draft.id_lokasi = action.dtRequest?.id_lokasi;
    
        if(draft.jns_approval_code == '6'){
          draft.approval_details = action.dtRequest?.pengumuman_unit_detal;
          draft.approval_details.forEach(responseResult => {
            const idx = draft.approval_details.findIndex(
              result => result.id === responseResult.id,
            );
            draft.approval_details.splice(idx, 1, {
              ...draft.approval_details[idx],
              attachment: draft.approval_details[idx].peng_unit_attech,
            });
          });
          
          draft.id_lokasi_tujuan = action.dtRequest?.id_lokasi;
        }else{
          draft.approval_details = action.dtRequest?.permintaan_unit_detail;
          draft.approval_details.forEach(responseResult => {
            const idx = draft.approval_details.findIndex(
              result => result.id === responseResult.id,
            );
            draft.approval_details.splice(idx, 1, {
              ...draft.approval_details[idx],
              attachment: draft.approval_details[idx].permintaan_unit_attachment,
            });
          });
        }
        
        draft.loading = false;
        break;
      case constants.GET_LIST_UNIT:
        draft.list_units = [];
        draft.loading_all_unit = true;
      break;
      case constants.GET_LIST_UNIT_FAIL:
        draft.error = true;
        draft.loading_all_unit = false;
        break;
      case constants.GET_LIST_UNIT_LOADING:
        draft.loading_all_unit = true;
        break;
      case constants.GET_LIST_UNIT_SUCCESS:
        draft.list_units = action.results;
        draft.loading_all_unit = false;
        break;  
    }
  });

export default ApprovalFormScreenReducer;
