import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
 
  import * as constants from './constants';
  import api from '../../util/api';
  import helper from '../../util/helper';
  import history from '../../util/history';
  // import * as RootNavigation from '../../../RootNavigation';
  
  import makeSelectWastePickupFormDetailScreen from './selector';
  import { getDetailTodoFail, getDetailTodoSuccess, submitPengambilanLimbahFail, submitPengambilanLimbahSuccess, submitPreventifFail, submitPreventifSuccess } from './actions';
import moment from 'moment';
  
  export function* doGetPreventifTodo(action) {
    const {id_realisasi_preventif, id_inventaris} = action;
    try {
      const response = yield call(api.preventif.getPreventifTodo, id_realisasi_preventif, id_inventaris);
      yield put(
        getDetailTodoSuccess(response.data),
      );
      console.log("form-res:", response.data);
    } catch (err) {
      console.log(err);
      helper.errorToast("Terjadi kegagalan proses ambil data pastikan koneksi internet anda normal atau segera hubungi admin");
      yield put(getDetailTodoFail(err));
    }
  }

  export function* doSubmitPengambilanLimbah(action) {
    const {id_branch, origin_screen} = action;
    const dataset = yield select(makeSelectWastePickupFormDetailScreen());

    // findIndex ApvKasie
    const id_peg_request = dataset.id_approval_request;
    const idxApvKasie = dataset.dtApproval?.approvalkasie_detail?.findIndex(raw => raw.id_approval_request === id_peg_request);
    let dtApvKasie = null;

    if(idxApvKasie >= 0){
      dtApvKasie = dataset.dtApproval?.approvalkasie_detail[idxApvKasie];
    } else {
      helper.errorToast('Data Approval kasie tidak ditemukan');
      yield put(submitPengambilanLimbahFail());
      return;
    }

    // findIndex BA
    const idxBA = dataset.dtApproval?.approval_ba?.ba_detail?.findIndex(raw => raw.id_approval_request === id_peg_request);
    let dtBADetail = null;
    if(idxBA >= 0){
      dtBADetail = dataset.dtApproval?.approval_ba?.ba_detail[idxBA];
    } else {
      helper.errorToast('Data BA tidak ditemukan');
      yield put(submitPengambilanLimbahFail());
      return;
    }

    try {
      const params = {
          id_vendor: dataset.dtApproval?.id_vendor,
          id_task_vendor: dataset.dtApproval?.approval_task_pihak3?.id,
          id_pengumuman_request: dtApvKasie.approval_request?.pengumuman_unit?.id_pengumuman_req,
          id_pengumuman_unit: dtApvKasie.approval_request?.pengumuman_unit?.id,
          id_approval: dtApvKasie.id_approval,
          id_lokasi: dataset.dtApproval?.id_lokasi_tujuan,
          id_approval_detail: dtApvKasie.id,
          id_approval_request: dtApvKasie.id_approval_request,
          id_pengumuman_unit_detail: dtApvKasie.approval_request?.pengumuman_unit_detail?.id,
          id_limbah_unit: dtApvKasie.approval_request?.limbah_unit?.id,
          id_ba_detail: dtBADetail.id,
          id_ba: dataset.dtApproval?.approval_ba?.id,
          id_unit_asal: (dataset.id_pengambilan_limbah == 0 ? dataset.dtApproval?.id_unit_tujuan : dataset.dtAmbilLimbah?.id_unit_asal),
          id_type_limbah: dtApvKasie.approval_request?.ms_jns_limbah?.id,
          code_limbah: dtApvKasie.approval_request?.ms_jns_limbah?.code,

          no_kendaraan: dataset.no_kendaraan,
          manifest_path: dataset.manifest_file,
          tujuan: dataset.tujuan,
          tgl_pengambilan: moment().format("YYYY-MM-DD hh:mm:ss"),
          noted: dataset.description,
          noted_header: dataset.noted_header,
          code_manifest: dataset.code_manifest,
          volume: dataset.volume,
          berat: dataset.berat,
          id: dataset.id_pengambilan_limbah,
          pengambilan_attachment: JSON.stringify(dataset.assets),
          
      };

      // console.log(params);

      if(helper.isEmpty(params.noted_header)) delete params.noted_header;
      if(helper.isEmpty(params.code_manifest)) delete params.code_manifest;

      const response = yield call(api.waste.submitWastePickup, params);
      yield put(
        submitPengambilanLimbahSuccess(response.data),
      );
      helper.successToast("Data berhasil disimpan");
      console.log("res:", response.data);
      
      setTimeout(() => {
        history.replace('waste-pickup-form', {...dataset.locationState, id_pengambilan_limbah: response.data?.id, viewOnly: true}); 
      }, 1000);
  
    } catch (err) {
      console.log(err);
      helper.errorToast("Transaksi tidak dapat disimpan pastikan detail berikut terisi :\n\r1. No kendaraan wajib terisi\n\r2. Perusahaan tujuan wajib terisi\n\r3. Jenis limbah wajib terisi\n\r4. Volume wajib terisi\n\r5. Berat wajib terisi\n\r6. Deskripsi wajib terisi\n\r7. Foto wajib terisi terisi");
      yield put(submitPengambilanLimbahFail(err));
    }
  }

  // Individual exports for testing
  export default function* wastePickupFormDetailScreenSaga() {
    yield all([
      takeLatest(constants.GET_DETAIL_TODO, doGetPreventifTodo),
      takeLatest(constants.SUBMIT_PENGAMBILAN_LIMBAH, doSubmitPengambilanLimbah),
    ]);
  }
  