import React, { Component, useState, useEffect, forwardRef, useRef } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectApprovalScreen from './selector';
import reducer from './reducer';
import saga from './saga';

import {
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Stack,
  Badge,
  Input,
  Image,
  IconButton,
  CheckIcon,
  Text,
  ScrollView, 
  Menu,
  Pressable,
  Actionsheet,
  FormControl,
  Button,
  Select,
  Flex } from 'native-base';
import DatePicker from "react-datepicker";
import {MdCalendarToday, MdModeEdit, MdMoreVert, MdRemoveRedEye, MdAddTask, MdPeopleAlt, MdThumbUp} from 'react-icons/md';
import {FaCalendarAlt, FaEnvelope, FaHammer, FaRegBuilding, FaHouseUser } from 'react-icons/fa';
import moment from 'moment';
import { ContainerPage } from '../../components/ContainerPage';
import CardApprovalList from '../../components/CardApprovalList';
import CardListPreloader from '../../components/CardListPreloader';
import MyAlert from '../../components/MyAlert';
import { commonStyle } from '../../styles';
import { defaultAction, getApprovalDetail, getListApproval, getMoreListApproval, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';
import history from '../../util/history';
import GlobalVar from '../../util/globalVar';
import makeSelectGlobalVar from '../../util/selector';
import { getLantai } from '../../util/actions';
import MyAlertDialog from '../../components/MyAlertDialog';
import MySelect2 from '../../components/MySelect2';
import RangeDateCustom from '../../components/RangeDateCustom';
import InfiniteScroll from 'react-infinite-scroll-component';

export function ApprovalScreen({
  dispatch,
  navigation,
  globalVar,
  approvalScreen,
  onUpdateInput,
  onGetResult,
  onGetMoreResult,
  onGetLantai,
  onSubmitApproval
}){
  const scrollableRef = useRef(null);
  const [screenAccess, setScreenAccess] = useState([]);
  const [tabType, setTabType] = useState(10);
  const [isOpen, setOnOpen] = useState(false);
  const [isOpenDialog, setOpenDialog] = useState(false);
  const [rawApv, setRawApv] = useState();
  const type_options = [
    { value: '0', label: 'All' },
    { value: '1', label: 'Preventif' },
    { value: '2', label: 'Verif Laporan Kerusakan' },
    { value: '3', label: 'Aproval Penawaran Vendor' },
    { value: '4', label: 'Verif Progres Report Vendor' }
  ];
  
  useEffect(() => {
    // onUpdateInput('id_ruang', id_item);
    // get user privileges
    const menus = api.getUserPrivileges();
    const allAccess = helper.generatePrivileges(menus);
    setScreenAccess(allAccess);
      // console.log(allAccess);

    onGetResult();
    
  },[]);

  useEffect(() => {
    onUpdateInput('responseState','');
    
    if(approvalScreen.responseState == 'approved'){
      helper.successToast("Anda telah menyetujui laporan ini");
      setOpenDialog(false);
    }
    if(approvalScreen.responseState == 'done'){
      helper.successToast("Anda telah menyelesaikan laporan ini");
    }
    if(approvalScreen.responseState == 'rejected'){
      helper.warningToast("Anda telah menolak laporan ini")
    }
    if(approvalScreen.responseState == 'error'){
      helper.errorToast("Aproval pemeliharaan belum berhasil")
    }
  },[approvalScreen.responseState]);

  const onChangeStartDt = (selectedDate) => {
    const currentDate = selectedDate;
    onUpdateInput("start_dt", currentDate);
    onUpdateInput("end_dt", currentDate);
    // onGetResult();
  };
  const onChangeEndDt = (selectedDate) => {
    const currentDate = selectedDate;
    onUpdateInput("end_dt", currentDate);
    onGetResult();
  };

  const onChangeUnit = (id_unit) => {
    onUpdateInput("id_unit", id_unit);
  };

  // const handleOpenStartDate = () => {
  //   DateTimePickerAndroid.open({
  //     value: approvalScreen.start_dt,
  //     onChange: onChangeStartDt,
  //     maximumDate: approvalScreen.end_dt,
  //     mode: 'date',
  //     is24Hour: true,
  //   });
  // }
  
  // const handleOpenEndDate = () => {
  //   DateTimePickerAndroid.open({
  //     value: approvalScreen.end_dt,
  //     onChange: onChangeEndDt,
  //     minimumDate: approvalScreen.start_dt,
  //     mode: 'date',
  //     is24Hour: true,
  //   });
  // }

  const handleGetData = () => {
    onGetResult()
  }

  const renderItem = ({item}) => {
    return (
      <Box px={3} mb={5}>
        <CardApprovalList data={item} access={screenAccess} navigation={navigation} />
      </Box>
    );
  }

  const getItem = (_data, index) => ({..._data[index]});
  
  const getItemCount = _data => approvalScreen.results.length;

  const getItemLayout = (data, index) => ({length: 360, offset: 360 * index, index});

  const InputStartDate = forwardRef(({ value, onClick }, ref) => (
    <Input 
      width="full"
      value={moment(approvalScreen.start_dt).format("DD/MM/YYYY")} 
      isReadOnly="true"
      onFocus={onClick} 
      borderRadius="lg" 
      variant="filled"
      ref={ref} 
      borderColor="primary.900" 
      InputLeftElement={<Icon as={<Box><MdCalendarToday/></Box>} size={5} ml="2" color="muted.400" />} 
      />
  ));

  const InputEndDate = forwardRef(({ value, onClick }, ref) => (
    <Input 
      value={moment(approvalScreen.end_dt).format("DD/MM/YYYY")} 
      isReadOnly="true"
      onFocus={onClick} 
      borderRadius="lg" 
      variant="filled"
      ref={ref} 
      borderColor="primary.900" 
      InputLeftElement={<Icon as={<Box><MdCalendarToday/></Box>} size={5} ml="2" color="muted.400" />} 
      />
  ));

  const onChangeStatus = (status) => {
    // setTabType(status);
    // let status_str = '10';
    // if(status == 15 ) status_str = '15';
    // if(status == 30 ) status_str = '30';
    onUpdateInput('status', status);
    onUpdateInput('page', 1);
    onGetResult();
  }

  const handleOnApprove = (data) => {
    console.log('rawApv:', data);
    setRawApv(data);
    setOpenDialog(true);
  }

  const handleDoSubmitApproval = () => {
    onSubmitApproval(rawApv);
    // onUpdateInput('loading_approval', true);
    // setTimeout(() => {
    //   helper.successToast('Approval Under Maintenance');
    //   onUpdateInput('loading_approval', false);
    //   setOpenDialog(false);
    // }, 3000);
  }

  const handleLoadMore = () => {
    console.log('load more:', approvalScreen.page);
    if(approvalScreen.page < approvalScreen.total_page){
      onUpdateInput('page', approvalScreen.page + 1);
    }
    onGetMoreResult();
  }

  return (
        <ContainerPage options={{statusBar: true, prevPage: 'home', title: 'Aproval', enableAct: true, onPressAction : () => setOnOpen(true)}}>
          <Box mt={3} mx={3}>
            {/* <Heading size="md">Aproval Pemeliharaan</Heading> */}
            {/* <Text fontWeight="bold" mb="1">Periode</Text>
            <Stack direction="row" mb={3} justifyContent="space-between">
            <Box flex={1}>
              <DatePicker customInput={<InputStartDate/>} portalId='root-portal' className='input-datepicker' popperClassName='pooper-datepicker' selected={approvalScreen.start_dt} onChange={(date) => onChangeStartDt(date)} maxDate={approvalScreen.end_dt} />
            </Box>
            <Text fontWeight="bold" mx={2} alignSelf="center">s.d</Text>
            <Box flex={1}>
              <DatePicker customInput={<InputEndDate/>} portalId='root-portal' className='input-datepicker' popperClassName='pooper-datepicker' selected={approvalScreen.end_dt} onChange={(date) => onChangeEndDt(date)} minDate={approvalScreen.start_dt} />
            </Box>
            </Stack> */}
            <div className='row'>
            <div className='col-4 p-3'>
              <Pressable onPress={() => onChangeStatus(10)}>
                <Flex flex={1}>
                    <Box backgroundColor={approvalScreen.status == 10 ? `#22C2E5`:`white`} pt={2} pb={5} px={5} borderRadius="3xl" shadow={3}>
                      <VStack alignItems="center">
                        <Box ml={-3} alignSelf="start" p={2} backgroundColor={approvalScreen.status == 10 ? `white`:`#22C2E5`} borderRadius="full">
                          <Icon as={<Box><MdPeopleAlt/></Box>} size="sm" color={approvalScreen.status == 10 ? `#22C2E5`:`white`} />
                        </Box>
                        <Box w="full" alignItems="center">
                          <Text fontSize="4xl" color={approvalScreen.status == 10 ? `white`:`#22C2E5`} fontWeight="bold">{approvalScreen.jml_permintaan}</Text>
                        </Box>
                        <Box w="full" alignItems="center">
                          <Text fontSize="xs" color={approvalScreen.status == 10 ? `white`:`dark.400`} fontWeight="bold">PERMINTAAN</Text>
                        </Box>
                      </VStack>
                    </Box>
                </Flex>
              </Pressable>
            </div>
            
            <div className='col-4 p-3'>
                <Pressable onPress={() => onChangeStatus(15)}>
                <Flex flex={1}>
                  <Box backgroundColor={approvalScreen.status == 15 ? `#FB865C`:`white`} pt={2} pb={5} px={5} borderRadius="3xl" shadow={3}>
                    <VStack alignItems="center">
                      <Box ml={-3} alignSelf="start" p={2} backgroundColor={approvalScreen.status == 15 ? `white`:`#FB865C`} borderRadius="full">
                        <Icon as={<Box><MdThumbUp/></Box>} size="sm" color={approvalScreen.status == 15 ? `#FB865C`:`white`} />
                      </Box>
                      <Box w="full" alignItems="center">
                        <Text fontSize="4xl" color={approvalScreen.status == 15 ? `white`:`#FB865C`} fontWeight="bold">{approvalScreen.jml_disetujui}</Text>
                      </Box>
                      <Box w="full" alignItems="center">
                        <Text fontSize="xs" color={approvalScreen.status == 15 ? `white`:`dark.400`} fontWeight="bold">DISETUJUI</Text>
                      </Box>
                    </VStack>
                  </Box>
                </Flex>
                </Pressable>
            </div>
            <div className='col-4 p-3'>
                <Pressable onPress={() => onChangeStatus(30)}>
                <Flex flex={1}>
                  <Box backgroundColor={approvalScreen.status == 30 ? `#2AB5A0`:`white`} pt={2} pb={5} px={5} borderRadius="3xl" shadow={3}>
                    <VStack alignItems="center">
                      <Box ml={-3} alignSelf="start" p={2} backgroundColor={approvalScreen.status == 30 ? `white`:`#2AB5A0`} borderRadius="full">
                        <Icon as={<Box><MdCalendarToday/></Box>} size="sm" color={approvalScreen.status == 30 ? `#2AB5A0`:`white`} />
                      </Box>
                      <Box w="full" alignItems="center">
                        <Text fontSize="4xl" color={approvalScreen.status == 30 ? `white`:`#2AB5A0`} fontWeight="bold">{approvalScreen.jml_selesai}</Text>
                      </Box>
                      <Box w="full" alignItems="center">
                        <Text fontSize="xs" color={approvalScreen.status == 30 ? `white`:`dark.400`} fontWeight="bold">SELESAI</Text>
                      </Box>
                    </VStack>
                  </Box>
                </Flex>
                </Pressable>
            </div>
          </div>
          </Box>
          <Flex flex={1}>
            <ScrollView ref={scrollableRef} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
              {
                approvalScreen.loading && <VStack space={7} px={3} mt={2} mb={35}>
                  {[...Array(3)].map((x, i) => (
                    <CardListPreloader key={i} />
                  ))}
                </VStack>
              }
              {
                !approvalScreen.loading && <>
                {!approvalScreen.results && <MyAlert status="info" title="Maaf, Hasil belum ditemukan."/>}
                {approvalScreen.results && 
                approvalScreen.results.length == 0 && <MyAlert status="info" title="Maaf, Hasil belum ditemukan."/>}
                {approvalScreen.results &&
                approvalScreen.results.length > 0 && 
                <InfiniteScroll
                  key={scrollableRef.current}
                  dataLength={approvalScreen.results?.length}
                  next={handleLoadMore}
                  hasMore={(approvalScreen.page < approvalScreen.total_page)}
                  loader={<p style={{textAlign:'center'}}>Memuat Lebih...</p>}
                  scrollableTarget={scrollableRef.current}
                >
                  <VStack space={7} px={3} mt={2} mb={35}>
                    {approvalScreen.results.map((row, i) => (
                        <CardApprovalList 
                        key={i} 
                        data={row} 
                        access={screenAccess} 
                        history={history} 
                        cardType={approvalScreen.status}
                        actionApprove={data => handleOnApprove(data)}
                        />
                    ))}
                  </VStack>
                  </InfiniteScroll>
                  }
                </>
              }
              {/* <VStack space={7} px={3} mt={2} mb={35}>
                {[...Array(3)].map((x, i) => (
                  <CardApprovalList 
                  key={i} 
                  // data={row} 
                  access={screenAccess} 
                  history={history} 
                  cardType={tabType}
                  // actionApprove={data => handleOnApprove(data)}
                  />
                ))}
              </VStack> */}
            </ScrollView>
          </Flex>
          <MyAlertDialog 
            alertTitle="Aproval Pemeliharaan" 
            alertDesc={`Apakah anda ingin Menyetujui laporan ini ?`}
            alertNoLabel="Tidak"
            alertYesLabel="Ya"
            alertIsOpen={isOpenDialog}
            alertOnClose={() => setOpenDialog(false)}
            alertOnConfirm={() => handleDoSubmitApproval()}
            isLoading={approvalScreen.loading_approval}
            isDisabled={approvalScreen.loading_approval}
          />
          <Actionsheet isOpen={isOpen} onClose={() => setOnOpen(false)}>
            <Actionsheet.Content>
              <Box w="100%" h={60} px={4} justifyContent="center">
                <Text fontSize="16" color="gray.500" _dark={{
                color: "gray.300"
              }}>
                  Filter
                </Text>
              </Box>
              <VStack w="100%" px={4}>
                <Box>
  
                <FormControl mb="1">
                    <FormControl.Label>Tanggal</FormControl.Label>
                    <RangeDateCustom startDt={approvalScreen.start_dt} endDt={approvalScreen.end_dt} changeStartDt={(date) => onUpdateInput('start_dt', date)} changeEndDt={(date) => onUpdateInput('end_dt', date)} />
                  </FormControl>
                  <FormControl mb="1">
                    <FormControl.Label>Unit</FormControl.Label>
                    <MySelect2
                      key="opt-gedung"
                      isClearable
                      defaultValue="0"
                      placeholder="All"
                      menuPortalTarget={document.body}
                      getOptionLabel={option => `${option.nama}`}
                      getOptionValue={option => option.id}
                      options={globalVar.list_units}
                      value={globalVar.list_units.filter(({id}) => id === approvalScreen.id_unit)}
                      onChange={(selectedOption) => onChangeUnit((selectedOption==null) ? 0 : selectedOption.id)}
                    />
                  </FormControl>
                  <FormControl mb="1">
                    <FormControl.Label>Jenis Aproval</FormControl.Label>
                    {/* <MySelect2 
                      key="opt-lantai"
                      menuPortalTarget={document.body}
                      options={type_options}
                      onChange={(selectedOption) => onUpdateInput('code_proses', (selectedOption==null) ? 0 : selectedOption.value)}
                    /> */}
                    <Select 
                      variant='filled' 
                      borderColor="primary.900" 
                      selectedValue={approvalScreen.status} 
                      minWidth="200" 
                      accessibilityLabel="Pilih Jenis" 
                      placeholder="Pilih Jenis" 
                      _selectedItem={{endIcon: <CheckIcon size="5"/>}} 
                      my={2}
                      onValueChange={itemValue => onUpdateInput('status', itemValue)}>
                        <Select.Item label="Created" value="10" />
                        <Select.Item label="Batal" value="20" />
                        <Select.Item label="Done" value="30" />
                      </Select>
                  </FormControl>
                  </Box>
                  <Box my={1}>
                    <Button isLoading={approvalScreen.loading} onPress={() => onGetResult()} spinnerPlacement="end" isLoadingText="Loading..." shadow={3} colorScheme="primary">Terapkan</Button>
                  </Box>
              </VStack>
            </Actionsheet.Content>
          </Actionsheet>
        </ContainerPage>
      );
    
}

const styles = {
  errorTextStyle: {
    color: 'red',
    fontStyle: 'italic',
    fontWeight: 'bold',
    alignSelf: 'center'
  }
};

const mapStateToProps = createStructuredSelector({
  approvalScreen: makeSelectApprovalScreen(),
  globalVar: makeSelectGlobalVar(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onGetResult: () => dispatch(getListApproval()),
    // onGetLantai: (id_gedung) => dispatch(getLantai(id_gedung)),
    onSubmitApproval: (data) => dispatch(getApprovalDetail(data)),
    onGetMoreResult: () => dispatch(getMoreListApproval()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(ApprovalScreen);