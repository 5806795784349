import React, { Component, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectLoginScreen from './selector';
import reducer from './reducer';
import saga from './saga';

// import { ActivityIndicator, ScrollView, Dimensions, View, TouchableOpacity, Platform, useColorScheme, BackHandler } from 'react-native';
import { Container, Slide, Heading, Alert, Pressable, Icon, VStack, Image, Box, Text, Input, Button, FormControl, Stack, WarningOutlineIcon, Center, HStack, Flex } from 'native-base';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
// import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
// import NetInfo, { useNetInfo } from "@react-native-community/netinfo";
// import messaging from '@react-native-firebase/messaging';
// import DeviceInfo from 'react-native-device-info';
import { useClearCache } from 'react-clear-cache';
// import {
//   GoogleReCaptchaProvider,
//   GoogleReCaptcha
// } from 'react-google-recaptcha-v3';
import api from '../../util/api';
import { ContainerScreen } from '../../components/ContainerScreen';
import { commonStyle } from '../../styles';
import { login, updateInput } from './actions';
import logo from '../../logo.svg';
import { checkFCMToken } from '../../firebase';
import helper from '../../util/helper';
import history from '../../util/history';
import GoogleReCaptchaBox from '../../components/GoogleRecaptchaBox';

async function saveToken(token) {

  // Add the token to storage
  api.setTokenDevice(token);
}

export function LoginScreen({
  dispatch,
  navigation,
  loginScreen,
  onUpdateInput,
  onDoLogin
}){
      const [isDebug, setDebug] = useState(true); //set false is build production
    
      const [show, setShow] = useState(false);
      const [userAgent, setUserAgent] = useState();
      const [ipAddress, setIpAddress] = useState();
      const [tokenDevice, setTokenDevice] = useState();
      const { isLatestVersion, emptyCacheStorage } = useClearCache();
      const [updatingApp, setUpdatingApp] = useState(false);

      const cur_token = api.getToken();

      useEffect(()=>{
        // if both company id and token are available
        if (cur_token != null) {
            // check login
            history.replace('welcome');
        }
        // get user agent
        // DeviceInfo.getUserAgent().then(data =>{
        //   setUserAgent(data);
        // });
        // get net info
        // NetInfo.fetch().then(state => {
        //   // console.log("Connection type", state.type);
        //   // console.log("Is connected?", state.isConnected);
        //   // console.log("details:", state.details);
        //   if(state.isConnected) {
        //     setIpAddress(state.details.ipAddress);
        //   }
        // });
        // // get token device from firebase
        // api.getTokenDevice().then(data => {
        //   // console.log("tokenDevice:", data);
        //   setTokenDevice(data);
        // }).catch(err => {
        //     console.warn(err.message);
        // });
        // get client ip
        api.getClientIP().then(data => {
          setIpAddress(data);
        });

      }, []);

      const handleOnLogin = () => {
        let agent = navigator.userAgent;
        let tokenFCM = '6LeezX8pAAAAALMDBnS0ee8QqZKNdn_SvUYM982z123456'; //api.getTokenDevice();
        let client_ip = ipAddress;
        console.log("userAgent:", agent);
        console.log("ipAddress:", ipAddress);
        console.log("tokenDevice:", tokenFCM);

        // TEMP DISABLED
        // if(helper.isEmpty(tokenFCM)){
        //   dispatch(updateInput('loading', true));
        //   checkFCMToken().then((tokenFCM) => {
        //     console.log('tokenFCMDevice:', tokenFCM);
        //     onDoLogin(navigation, agent, client_ip, tokenFCM);
        //   }).catch((err) => {
        //     helper.warningToast('Perangkat tidak mendukung fitur notifikasi!');
        //     console.log('errFMC:', err);
        //     onDoLogin(navigation, agent, client_ip, tokenFCM);
        //   });

        //   return;
        // }
        
        // if(helper.isEmpty(tokenFCM)){
        //   helper.warningToast('Lakukan verifikasi Captcha terlebih dahulu!');
        //   return;
        // }
        
        onDoLogin(navigation, agent, client_ip, tokenFCM);
      }

      const handleOnUpdatingApp = (evt) => {
        evt.preventDefault();
        setUpdatingApp(true);
        emptyCacheStorage();
      }

      const handleGoogleCaptcha = useCallback((token) => {
        console.log('g-token', token);
        onUpdateInput('g_token', token);
      },[]);

      return (
          <ContainerScreen>
            {!isLatestVersion &&
            <Flex>
              <HStack space={1} justifyContent="space-between" alignItems="center">
                <Text fontSize="sm">New Version Available</Text>
                <Button size="sm" colorScheme="amber" variant="subtle" onPress={evt => handleOnUpdatingApp(evt)} spinnerPlacement="end" isLoading={updatingApp} isLoadingText="Loading">
                  Update Now
                </Button>
              </HStack>
            </Flex>
            }
            <Flex flex={1} justifyContent="center" mt={100}>
            <Box>
              <Center>
                <Image alt='logo' style={{width: 300, height:83, alignSelf:'center', marginBottom:20}} source={require('../../images/logo-white-h.png')}/>
              </Center>
            </Box>
              <Box>
                <Center>
                  <Box w="100%" maxWidth="300px">
                    <Heading size="md" my={3}>
                      Login
                    </Heading>
                    <FormControl isRequired>
                      <Stack>
                        <FormControl.Label>Email</FormControl.Label>
                        <Input type="email" value={loginScreen.username} onChangeText={txt => onUpdateInput('username', txt)} borderRadius="lg" variant="filled" backgroundColor="common.100" borderColor="warmGray.200" placeholder="Ketikkan Email" />
                        <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                          Email are required.
                        </FormControl.ErrorMessage>
                      </Stack>
                    </FormControl>
                    <FormControl isRequired>
                      <Stack>
                        <FormControl.Label>Password</FormControl.Label>
                        <Stack>
                          <Input value={loginScreen.password} onChangeText={txt => onUpdateInput('password', txt)} type={show ? "text" : "password"} backgroundColor="common.100" InputRightElement={<Pressable onPress={() => setShow(!show)}>
                                <Box mr={3}><Icon as={(show ? <MdVisibility /> : <MdVisibilityOff />)} size={5} mr="2" color="muted.400" /></Box>
                              </Pressable>} borderRadius="lg" variant="filled" borderColor="warmGray.200" placeholder="Ketikkan Password"  />
                        </Stack>
                        <FormControl.HelperText>
                          Must be atleast 6 characters.
                        </FormControl.HelperText>
                      </Stack>
                    </FormControl>
                    {loginScreen.error_msg != "" && <Text my={3} color="error.600">{loginScreen.error_msg}</Text>}
                    <Stack direction="row" my={4}>
                      <Box flex={2}>
                        <Button isLoading={loginScreen.loading} spinnerPlacement="end" isLoadingText="Submitting" onPress={() => handleOnLogin()}>Masuk</Button>
                      </Box>
                    </Stack>
                    <Box mx={4}>
                      <Flex flexDirection="row" justifyContent="flex-end">
                        <a href='/forgetpass'>
                          <Text style={commonStyle.linkText} fontWeight="bold">Lupa password ?</Text>
                        </a>
                      </Flex>
                    </Box>
                  </Box>
                </Center>
              </Box>
            </Flex>
          </ContainerScreen>
      );
    
}

const styles = {
  errorTextStyle: {
    color: 'red',
    fontStyle: 'italic',
    fontWeight: 'bold',
    alignSelf: 'center'
  }
};

const mapStateToProps = createStructuredSelector({
  loginScreen: makeSelectLoginScreen(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onDoLogin: (navigation, userAgent, ipAddress, tokenDevice) => dispatch(login(navigation, userAgent, ipAddress, tokenDevice)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(LoginScreen);