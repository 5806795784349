import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, deleteToken } from "firebase/messaging";
// import { getMessaging } from "firebase/messaging/sw";
import api from './util/api';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC6nlO8M-O9C4Pq_0yYq_3ta1wI1myC_8Q",
  authDomain: "my-app-local-project.firebaseapp.com",
  databaseURL: "https://my-app-local-project-default-rtdb.firebaseio.com",
  projectId: "my-app-local-project",
  storageBucket: "my-app-local-project.firebasestorage.app",
  messagingSenderId: "846452056052",
  appId: "1:846452056052:web:29f9ff5e6f032643b8a3a1",
  measurementId: "G-WD0B5EBCB6"
};

initializeApp(firebaseConfig);

export const messaging = getMessaging();

export const deleteFCMToken = () => deleteToken(messaging);

export const checkFCMToken = () => {return getToken(messaging, { vapidKey: 'BHISi8ysucTF7lxVvLRGgZO3clUPE_dkFpj7mmm-ckSxgufkoVdLbJMCkYTuSZeDhz36sbYpS617d_3Rl7Dfsdw' })};

export const requestForToken = () => {
  console.log('get FCM');
  // return getToken(messaging, { vapidKey: 'BGVXpBKy8GTjqVZF9hYSCCkz3X_RokGENe_MTw0LLjV8GmIF7Fx6g6MutTAB32nwasRRhtlvuV_72BhRUikfT0Q' })
  //   .then((currentToken) => {
  //   if (currentToken) {
  //       console.log('current token for client: ', currentToken);
  //       api.setTokenDevice(currentToken);
  //       // Perform any other neccessary action with the token
  //   } else {
  //       // Show permission request UI
  //       console.log('No registration token available. Request permission to generate one.');
  //   }
  //   })
  //   .catch((err) => {
  //     console.log('An error occurred while retrieving token. ', err);
  //     // alert('Izinkan Aplikasi untuk menggunakan fitur notifikasi');
  //   });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});
