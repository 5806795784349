import React, {memo} from "react";
import { commonStyle } from "../styles";
import {
    Icon,
    HStack, 
    Avatar, 
    Heading, 
    VStack, 
    Box, 
    Center,
    Stack,
    Badge,
    Input,
    Image,
    IconButton,
    Pressable,
    Divider,
    HamburgerIcon,
    Progress,
    Menu,
    Text, 
    Flex } from 'native-base';
  import {MdRemoveRedEye, MdModeEdit, MdHistory, MdMoreVert, MdDelete, MdDownload, MdDriveFileMove, MdFactCheck, MdChecklist, MdDeleteOutline, MdUploadFile } from 'react-icons/md';
  import {FaCalendarAlt, FaEnvelope, FaAngleDown, FaCalendarTimes, FaCalendarCheck, FaWrench, FaTruck } from 'react-icons/fa';
  import moment from "moment";
  import BadgeStatus from "./BadgeStatus";
  import helper from "../util/helper";
  import history from "../util/history";
  import { saveAs } from 'file-saver';

const CardWastePickupList = (props) => {
  const {data, access, onDelete} = props;
  const params = {
    id_pengambilan_limbah: data.id_pengambilan_limbah,
    id_approval: data.id_approval,
    jns_approval_code: data.jns_approval_code,
    new_data: true,
  };

  const  handleDownloadManifest = async () => {
    
    if(data.path){
      const file_ext = helper.getExtensionFromBase64(data.path);
      // window.open(data.path, '_blank');
      await saveAs(data.path, `file_${data.code_manifest?.replaceAll("/", "_")}.${file_ext}`);
      return;
    } else {
      helper.infoToast('File belum tersedia');
    }
  }

  return (
    <Box width="100%" backgroundColor={commonStyle.baseColor} shadow={3} borderRadius="lg">
      <VStack>
        <Box px={5} py={3} backgroundColor={commonStyle.cardBaseHeaderBg} borderTopRadius="lg">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box>
              <Icon color="darkText" as={<Box><FaCalendarAlt/></Box>} size={5}></Icon>
            </Box>
            <Box flex={1} ml={1}>
              <Text color="darkText" fontWeight={500} >
                {moment(data.tgl_permintaan, "DD-MM-YYYY").format("DD MMMM YYYY")}
              </Text>
            </Box>
            <Box ml={3}>
              <BadgeStatus status={data.status_name}/>
            </Box>
            <Box ml={2} alignItems="center" justifyContent="center" width={8} height={8}>
              <Menu mt={2} py={2} placement="bottom right" trigger={triggerProps => {
                return <Pressable accessibilityLabel="More options menu" {...triggerProps}>
                        <Icon as={<Box><MdMoreVert/></Box>} size={8} color="gray.600" />
                      </Pressable>;
              }}>
                {access.includes('4:VIEW') && 
                <Menu.Item p={1} onPress={() => history.push('waste-pickup-form', {...params, viewOnly: true})}>
                  <HStack space={2} justifyContent="center" alignItems="center">
                    <Icon as={<Box><MdRemoveRedEye/></Box>} size="md" color="info.700" />
                    <Text color="darkText" fontSize="md">
                      View
                    </Text>
                  </HStack>
                </Menu.Item>
                }
                {(access.includes('4:EDIT') && data.code_status == '10') &&  
                <Menu.Item p={1} onPress={() => history.push('waste-pickup-form', {...params, viewOnly: false})}>
                  <HStack space={2} justifyContent="center" alignItems="center">
                    <Icon as={<Box><MdDriveFileMove/></Box>} size="md" color="info.700" />
                    <Text color="darkText" fontSize="md">
                      Proses Pengambilan
                    </Text>
                  </HStack>
                </Menu.Item>
                }
                {(access.includes('4:DEL') && data.id_pengambilan_limbah > 0) &&
                <Menu.Item p={1} onPress={() => onDelete(data.id_pengambilan_limbah)}>
                  <HStack space={2} justifyContent="center" alignItems="center">
                    <Icon as={<Box><MdDelete/></Box>} size="md" color="info.700" />
                    <Text color="darkText" fontSize="md">
                      Hapus Permintaan
                    </Text>
                  </HStack>
                </Menu.Item>
                }
                {(access.includes('4:BA_DOWN') && ['30','40'].includes(data.code_status)) &&
                <Menu.Item p={1} onPress={() => handleDownloadManifest()}>
                  <HStack space={2} justifyContent="center" alignItems="center">
                    <Icon as={<Box><MdDownload/></Box>} size="md" color="info.700" />
                    <Text color="darkText" fontSize="md">
                      Download Manifest
                    </Text>
                  </HStack>
                </Menu.Item>
                }
                {(['30','40'].includes(data.code_status)) &&
                <Menu.Item p={1} onPress={() => history.push('waste-pickup-manifest', {...params, code_ambil_limbah: data.code_pengambilan_limbah})}>
                  <HStack space={2} justifyContent="center" alignItems="center">
                    <Icon as={<Box><MdUploadFile/></Box>} size="md" color="info.700" />
                    <Text color="darkText" fontSize="md">
                      Upload Manifest
                    </Text>
                  </HStack>
                </Menu.Item>
                }
              </Menu>
            </Box>
          </Stack>
        </Box>
        <Box p={5}>
          <VStack>
            <Flex flexDirection="row" mb={3} alignItems="center">
              <Box width={8} height={8} borderRadius="lg" backgroundColor="primary.400" justifyContent="center">
                <Center>
                  <Icon color="white" as={<Box><MdChecklist/></Box>} size={5}></Icon>
                </Center>
              </Box>
              <Box ml={2}>
                <Text color="darkText">
                  {data.code_approval}
                  
                </Text>
              </Box>
            </Flex>
            <Flex flexDirection="row" mb={3} alignItems="center">
              <Box width={8} height={8} borderRadius="lg" backgroundColor="primary.400" justifyContent="center">
                <Center>
                  <Icon color="white" as={<Box><MdDeleteOutline/></Box>} size={5}></Icon>
                </Center>
              </Box>
              <Box ml={2}>
                <Text color="darkText">
                  {data.code_pengambilan_limbah}
                </Text>
              </Box>
            </Flex>
            <HStack flex={1} mb={3} space={2}>
              <Box flex={1}>
                <VStack>
                  <Text color="darkText" bold>Tgl Pengambilan</Text>
                  <Text color="darkText">
                    {data.tgl_pengambilan}
                  </Text>
                </VStack>
              </Box>
              <Box flex={1}>
                <VStack>
                  <Text color="darkText" bold>Unit Tujuan</Text>
                  <Text color="darkText">
                    {data.nm_unit_tujuan}
                  </Text>
                </VStack>
              </Box>
            </HStack>
            <Box flex={1} mb={3}>
              <VStack>
                <Text color="darkText" bold>Catatan</Text>
                <Text color="darkText">
                  {data.catatan}
                </Text>
              </VStack>
            </Box>
            <Flex flexDirection="row" mb={3}>
              <Box>
                <Center>
                  <Avatar size="md" bg="primary.900" mr="1">
                      {helper.getInitials(data.nm_pegawai_unit)}
                  </Avatar>
                </Center>
              </Box>
              <VStack width="90%">
                <Text ml={3} fontSize="lg" color={commonStyle.primaryColor} isTruncated width="80%" fontWeight="bold">
                  {data.nm_pegawai_unit}
                </Text>
                <Text ml={3} fontSize="sm" color="muted.500" isTruncated width="80%">
                  {data.no_hp_unit}
                </Text>
              </VStack>
            </Flex>
            
          </VStack>
        </Box>
      </VStack>
  </Box>
  );
}

export default memo(CardWastePickupList);