import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectWastePickupFormManifestScreen from './selector';
import reducer from './reducer';
import saga from './saga';

// import {ScrollView,RefreshControl, BackHandler, PermissionsAndroid, StyleSheet, View, Dimensions } from 'react-native';
import {
  AspectRatio,
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Text,
  Pressable,
  TextArea,
  FormControl,
  Divider,
  Image,
  Modal,
  Flex,
  ScrollView, 
  Button,
  Select,
  CheckIcon,
  Stack,
  Menu,
  Fab,
  Input} from 'native-base';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import Files from 'react-files';
import 'react-html5-camera-photo/build/css/index.css';
import {FaCalendarAlt, FaCheckCircle} from 'react-icons/fa';
import {MdAddAPhoto, MdDelete, MdDownload, MdFileCopy, MdModeEdit, MdMoreVert, MdRemoveRedEye} from 'react-icons/md';
import { ContainerPage } from '../../components/ContainerPage';
import { commonStyle } from '../../styles';
import { addImage, defaultAction, delImage, delManifest, getPelaporan, getPengambilanUnitDetail, saveDetail, setManifest, submitPelaporan, submitPengambilanUnit, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';
import BadgeStatus from '../../components/BadgeStatus';
import ImageSlider from '../../components/ImageSlider';
import { AiOutlinePlus } from 'react-icons/ai';
import history from '../../util/history';
import LoadingOverlay from 'react-loading-overlay';

export function WastePickupFormManifestScreen({
  dispatch,
  location,
  navigation,
  wastePickupFormManifestScreen,
  onUpdateInput,
  onAddManifest,
  onDelManifest,
  onSubmitPengambilanUnit,
  onGetDetail,
  onSaveDetail
}){
  const {id_pengambilan_limbah, id_approval, jns_approval_code, code_ambil_limbah, new_data, viewOnly} = location.state;
  const[userData, setUserData] = useState();
  const [photo, setPhoto] = useState(null);
  const [openCamera, setOpenCamera] = useState(false);
  const [openModalCamera, setOpenModalCamera] = useState(false);

  const imageList = [
    {path: '../../images/pintu.jpg'},
    {path: '../../images/pintu.jpg'},
    {path: '../../images/pintu.jpg'},
  ];

  const status_detail = {
    '10': 'created',
    '20': 'batal',
    '30': 'approve',
    '40': 'done'
  };

  useEffect(() => {
    // reset params
    dispatch(defaultAction());

    onUpdateInput('id_pengambilan_limbah', id_pengambilan_limbah);
    onUpdateInput('id_approval', id_approval);
    onUpdateInput('jns_approval_code', jns_approval_code);
    onUpdateInput('code', code_ambil_limbah);

    //EDIT STATE
    // if(!helper.isEmpty(id_pengambilan_limbah)){
    //   const curdataReq = api.getFromLocalDB('unitReq');
    //   curdataReq.then((val) => {
    //     console.log('saved_pengambilan', val);
    //     if(helper.isEmpty(val) || id_pengambilan_limbah != val?.id_pengambilan_limbah){
    //       // get data if localDB is empty
    //       // onGetPermintaan(id_pengambilan_limbah);
    //     } else {
    //       // get data from localDB
    //       onUpdateInput('no_kendaraan', val.no_kendaraan);
    //       onUpdateInput('tujuan', val.tujuan);
    //       onUpdateInput('code_manifest', val.code_manifest);
    //       onUpdateInput('notes', val.notes);
    //       onUpdateInput('manifest_name', val.manifest_name);
    //       onUpdateInput('manifest_file', val.manifest_file);
    //     }
    //   }).catch(err => {
    //     console.log('err-localdb:', err)
    //   });

    // //ADD STATE
    // } else {
      console.log('new_data', new_data)
      if(new_data){
        // clear for fresh data
        api.clearItemLocalDB('wastePickup');
      } else {
        // get data from localDB
        const curdataReq = api.getFromLocalDB('wastePickup');
        curdataReq.then((val) => {
            // get data from localDB
            onUpdateInput('no_kendaraan', val.no_kendaraan);
            onUpdateInput('tujuan', val.tujuan);
            onUpdateInput('code_manifest', val.code_manifest);
            onUpdateInput('notes', val.notes);
            onUpdateInput('manifest_name', val.manifest_name);
            onUpdateInput('manifest_file', val.manifest_file);
        }).catch(err => {
          console.log('err-localdb:', err)
        });
      } 
      
    //}
    
    onGetDetail();
    console.log("id_l", id_pengambilan_limbah);
  },[]);
  

  const handleChoosePhoto = async (data) => {
    setOpenCamera(false);
    console.log("img:", data);
    setTimeout(() => {
      setOpenModalCamera(false);
    }, 300);
    if(wastePickupFormManifestScreen.assets.length == 4){
      helper.infoToast("Anda melebihi batas maksimum yakni 4 foto.");
      return;
    }

    try {
      if(!helper.isEmpty(data)) onAddManifest(data);
      // const granted = await PermissionsAndroid.request(
      //   PermissionsAndroid.PERMISSIONS.CAMERA,
      //   {
      //     title: "App Camera Permission",
      //     message:"App needs access to your camera",
      //     buttonNeutral: "Ask Me Later",
      //     buttonNegative: "Cancel",
      //     buttonPositive: "OK"
      //   }
      // );
      // if (granted === PermissionsAndroid.RESULTS.GRANTED) {
      //   console.log("Camera permission given");
      //   launchCamera({ 
      //     mediaType: 'photo',
      //     maxWidth: 800,
      //     maxHeight: 800,
      //     includeBase64: true,
      //     cameraType: 'back'
      //   }, (response) => {
      //     console.log(response);
      //     if (response) {
      //       if(!helper.isEmpty(response.assets)) onAddManifest(response.assets[0]);
      //     }
      //   });
      // } else {
      //   console.log("Camera permission denied");
      // }
    } catch (err) {
      console.warn(err);
    }
  };

  const handleSubmit = () => {
    // if(helper.isEmpty(wastePickupFormManifestScreen.description) || wastePickupFormManifestScreen.description.length < 10){
    //   helper.warningToast("Isikan deskripsi kerusakan, Minimal 10 karakter.");
    //   return;
    // }
    // if(wastePickupFormManifestScreen.assets.length == 0){
    //   helper.warningToast("Bukti foto diperlukan min 1 foto.");
    //   return;
    // }

    // console.log('desc:',wastePickupFormManifestScreen.description.length);

    onSubmitPengambilanUnit();
  }

  const handleGetData = () => {
    if(id_pengambilan_limbah > 0){
      onGetDetail();
    }
  }

  const handleCameraOpen = () => {
    setOpenModalCamera(true);
    setTimeout(() => {
      setOpenCamera(true);
    }, 300);
  }

  function handleTakePhotoAnimationDone (dataUri) {
    // Do stuff with the photo...
    // console.log('takePhoto');
  }

  function handleCameraError (error) {
    // console.log('handleCameraError', error);
  }

  function handleCameraStart (stream) {
    // console.log('handleCameraStart : ', stream);
  }

  function handleCameraStop () {
    // console.log('handleCameraStop');
  }

  const getBase64 = file => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };

  const handleFileChange = (files) => {
    console.log(files);
    const file = files[0];
    getBase64(file)
      .then(result => {
        file["base64"] = result;
        console.log("File Is", file);
        const params = {
          base64: result,
          type: file.type,
          name: file.name,
        }

        onAddManifest(params);
      })
      .catch(err => {
        console.log(err);
      });
  }

  const handleFileError = (error, file) => {
    console.log('error code ' + error.code + ': ' + error.message)
    helper.errorToast(error.message);
  }

  const handleSubmitFormDetail = (data, act) => {
    const check_param = {
        no_kendaraan: wastePickupFormManifestScreen.no_kendaraan,
        tujuan: wastePickupFormManifestScreen.tujuan
        // code_manifest: wastePickupFormManifestScreen.code_manifest,
        // manifest_name: wastePickupFormManifestScreen.manifest_name,
        // manifest_file: wastePickupFormManifestScreen.manifest_file,
    };

    const isAllFilled = (obj) => {
        return Object.values(obj).every(value => value !== "" && value !== null && value !== undefined);
    };
    
    if (!isAllFilled(check_param)) {
        helper.warningToast('Mohon lengkapi semua data!');
        return;
    }

    onSaveDetail();
    const params = {
      id_pengambilan_limbah: wastePickupFormManifestScreen.id_pengambilan_limbah,
      id_approval: wastePickupFormManifestScreen.id_approval,
      jns_approval_code: wastePickupFormManifestScreen.jns_approval_code,
      id_approval_request: data.id_approval_request,
      id_approval_kasie: data.id,
    };

    history.push('waste-pickup-form-detail', {...params, readOnly: (act == 'view')});
  } 

  return (
        <ContainerPage options={{statusBar: true, prevPage: 'waste-pickup', title: 'Pengambilan Limbah', params:{}}}>
          <Flex flex={1} minW="300px">
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
              <LoadingOverlay
                active={wastePickupFormManifestScreen.loading}
                spinner
                text='Loading...'
                >
              <VStack space={7} mt={2} mb={35} mx={3}>
                {wastePickupFormManifestScreen.code && 
                <Box mt={5}>
                  <Center>
                    <HStack space={2} justifyContent="center" alignItems="center">
                      <Icon as={<Box><FaCheckCircle/></Box>} size="8" color="success.700" />
                      <Text color="darkText" fontWeight="bold" isTruncated maxWidth="90%" fontSize="lg">
                        {wastePickupFormManifestScreen.code}
                      </Text>
                    </HStack>
                  </Center>
                </Box>
                }
                <Box p={5} borderRadius="lg" shadow={3} backgroundColor="white">
                    <VStack>
                      <Box mb={2}>
                        <FormControl mb="1" isRequired>
                          <FormControl.Label>Code Manifest</FormControl.Label>
                          <Input borderRadius="lg" value={wastePickupFormManifestScreen.code_manifest} onChange={evt => onUpdateInput('code_manifest', evt.currentTarget.value)} />
                        </FormControl>
                        <FormControl mb="1" isRequired>
                          <FormControl.Label>Upload Manifest</FormControl.Label>
                              {!wastePickupFormManifestScreen.manifest_name && 
                                <Box justifyContent="center" alignItems="center" p={2} borderColor="muted.400" borderRadius="lg" borderWidth={1} borderStyle="dashed">
                                  <Files
                                    className='files-dropzone'
                                    onChange={handleFileChange}
                                    onError={handleFileError}
                                    accepts={['image/png', 'image/jpg','image/jpeg', '.pdf']}
                                    maxFileSize={(10000000 * 20)} // 20 MB
                                    minFileSize={0}
                                    clickable>
                                    Drop files here or click to upload
                                  </Files>
                                </Box>
                              }
                              {wastePickupFormManifestScreen.manifest_name &&
                                <HStack justifyContent="center" alignItems="center">
                                  <Flex flex={1} flexDirection="column">
                                    <Text fontSize="sm" fontWeight="bold">{wastePickupFormManifestScreen.manifest_name}</Text>
                                  </Flex>
                                  <Box>
                                    {!viewOnly &&
                                      <Pressable onPress={() => onDelManifest()}>
                                        <Icon as={<Box><MdDelete/></Box>} color="red.500" size="lg"></Icon>
                                      </Pressable>
                                    }
                                  </Box>
                                </HStack>
                              }
                        </FormControl>
                      </Box>
                    </VStack>
                </Box>
                
                <Box>
                    <Button isLoading={wastePickupFormManifestScreen.loading} spinnerPlacement="end" isLoadingText="Submitting..." colorScheme="primary" onPress={() => handleSubmit()}>Simpan</Button>
                    
                </Box>
              </VStack>
              </LoadingOverlay>
            </ScrollView>
          </Flex>
        </ContainerPage>
      );
    
}

const mapStateToProps = createStructuredSelector({
  wastePickupFormManifestScreen: makeSelectWastePickupFormManifestScreen(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onAddManifest: manifest => dispatch(setManifest(manifest)),
    onDelManifest: () => dispatch(delManifest()),
    onSubmitPengambilanUnit: () => dispatch(submitPengambilanUnit()),
    onGetDetail: () => dispatch(getPengambilanUnitDetail()),
    onSaveDetail: () => dispatch(saveDetail())
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(WastePickupFormManifestScreen);