import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
import api from '../../util/api';
import helper from '../../util/helper';
import history from '../../util/history';
// import * as RootNavigation from '../../../RootNavigation';
import { getPelaporanFail, getPelaporanSuccess, getPermintaanFail, getPermintaanSuccess, submitPermintaanFail, submitPermintaanSuccess } from './actions';

  import * as constants from './constants';
  
  import makeSelectUnitRequestFormScreen from './selector';
  
  export function* doSubmitPermintaan(action) {
    const dataset = yield select(makeSelectUnitRequestFormScreen());
    try {
      const params = {
          id_unit: dataset.id_unit,
          id_lokasi: dataset.id_lokasi,
          noted: dataset.notes,
          permintaan_detail: JSON.stringify(dataset.saved_details),
          id: (helper.isEmpty(dataset.id_permintaan) ? 0 : dataset.id_permintaan),
      };

      if(helper.isEmpty(params.noted)) delete params.noted;

      const response = yield call(api.unit.submitRequest, params);
      yield put(
        submitPermintaanSuccess(response.data),
      );
      helper.successToast("Data berhasil disimpan");
      // console.log("res:", response.data);
      // clear local DB
      api.clearItemLocalDB('unitReqDetail');
      api.clearItemLocalDB('unitReq');
      history.replace('unit-request');
    } catch (err) {
      console.log(err);
      helper.errorToast("Transaksi tidak dapat disimpan pastikan detail berikut terisi : \n\r 1. Unit / fakultas terisi \n\r 2. Lokasi terisi \n\r 3. Memiliki minimal 1 data detail limbah");
      yield put(submitPermintaanFail(err));
    }
  }

  export function* doGetPermintaan(action) {
    const dataset = yield select(makeSelectUnitRequestFormScreen());
    try {

      const params = {
        id_permintaan_unit: action.id_permintaan,
      };

      const response = yield call(api.unit.getRequest, params);
      const {success} = response.data;

      console.log('raw-request:', success);
      yield put(
        getPermintaanSuccess(success),
      );
    } catch (err) {
      console.log(err);
      helper.errorToast("Terjadi kegagalan proses ambil data pastikan koneksi internet anda normal atau segera hubungi admin");
      yield put(getPermintaanFail(err));
    }
  }

  export function* doSaveDetail(action) {
    const {idx_detail} = action;
    const dataset = yield select(makeSelectUnitRequestFormScreen());
    try {
      const cur_details = dataset.saved_details;
      console.log('saga_cur_details', cur_details)
      api.saveToLocalDB('unitReqDetail', [...cur_details]);
      // helper.successToast("Data berhasil disimpan");
      // console.log("res:", );
  
    } catch (err) {
      console.log(err);
      helper.errorToast("Gagal Menyimpan data, silahkan hub admin");
    }
  }

  export function* doSetEditData(action) {
    const {idx_detail} = action;
    const dataset = yield select(makeSelectUnitRequestFormScreen());
    try {
      const cur_details = dataset.saved_details;
      console.log('saga_cur_details', cur_details)
      api.saveToLocalDB('unitReqDetail', [...cur_details]);
      api.saveToLocalDB('unitReq', {
        id_permintaan: dataset.id_permintaan,
        id_unit: dataset.id_unit,
        id_lokasi: dataset.id_lokasi,
        notes: dataset.notes,
        code_permintaan: dataset.code_permintaan,
      });
      // helper.successToast("Data berhasil disimpan");
      // console.log("res:", );
  
    } catch (err) {
      console.log(err);
      helper.errorToast("Gagal Menyimpan data, silahkan hub admin");
    }
  }


  // Individual exports for testing
  export default function* unitRequestFormScreenSaga() {
    yield all([
      takeLatest(constants.SUBMIT_PERMINTAAN, doSubmitPermintaan),
      takeLatest(constants.GET_PERMINTAAN, doGetPermintaan),
      takeLatest(constants.DEL_SAVED_DETAIL, doSaveDetail),
      takeLatest(constants.GET_PERMINTAAN_SUCCESS, doSetEditData),
      takeLatest(constants.SET_SAVED_DETAIL, doSetEditData),
    ]);
  }
  