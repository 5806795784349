export const DEFAULT_ACTION = 'screen/ApprovalFormScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/ApprovalFormScreen/UPDATE_INPUT';

export const SUBMIT_APPROVAL = 'screen/ApprovalFormScreen/SUBMIT_APPROVAL';
export const SUBMIT_APPROVAL_LOADING = 'screen/ApprovalFormScreen/SUBMIT_APPROVAL_LOADING';
export const SUBMIT_APPROVAL_SUCCESS = 'screen/ApprovalFormScreen/SUBMIT_APPROVAL_SUCCESS';
export const SUBMIT_APPROVAL_FAIL = 'screen/ApprovalFormScreen/SUBMIT_APPROVAL_FAIL';

export const ADD_IMAGE = 'screen/ApprovalFormScreen/ADD_IMAGE';
export const DEL_IMAGE = 'screen/ApprovalFormScreen/DEL_IMAGE';

export const GET_APPROVAL_DETAIL = 'screen/ApprovalFormScreen/GET_APPROVAL_DETAIL';
export const GET_APPROVAL_DETAIL_LOADING = 'screen/ApprovalFormScreen/GET_APPROVAL_DETAIL_LOADING';
export const GET_APPROVAL_DETAIL_SUCCESS = 'screen/ApprovalFormScreen/GET_APPROVAL_DETAIL_SUCCESS';
export const GET_APPROVAL_DETAIL_FAIL = 'screen/ApprovalFormScreen/GET_APPROVAL_DETAIL_FAIL';

export const GET_LIST_UNIT = 'screen/ApprovalFormScreen/GET_LIST_UNIT';
export const GET_LIST_UNIT_LOADING = 'screen/ApprovalFormScreen/GET_LIST_UNIT_LOADING';
export const GET_LIST_UNIT_SUCCESS = 'screen/ApprovalFormScreen/GET_LIST_UNIT_SUCCESS';
export const GET_LIST_UNIT_FAIL = 'screen/ApprovalFormScreen/GET_LIST_UNIT_FAIL';