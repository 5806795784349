/*
 *
 * HomeScreen reducer
 *
 */
import {produce} from 'immer';
import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';
import moment from 'moment';

const date = new Date();
const from = moment(date).toDate();
const to = moment(date).toDate();

export const initialState = {
    error: 0,
    error_msg: '',
    loading: false,
    id_gedung: null,
    start_dt: from,
    end_dt: to,
    dashboard: [],
    tasks: [],
    qty_berat: 0,
    qty_volume: 0,
    qty_ambil: 0,
    qty_transfer: 0,
};

/* eslint-disable default-case, no-param-reassign */
const HomeScreenReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
        break;
      case constants.UPDATE_INPUT:
        draft[action.name] = action.value;
        break;
      case constants.GET_DASHBOARD:
        draft.loading = true;
        break;
      case constants.GET_DASHBOARD_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.dashboard = [];
        break;
      case constants.GET_DASHBOARD_SUCCESS:{
          draft.dashboard = action.results;
          draft.loading = false;
          const dtDashboard = action.results;
          if(dtDashboard.length > 0){
            draft.qty_berat = dtDashboard[0].tot_berat_bulat;
            draft.qty_volume = dtDashboard[0].tot_vol_bulat;
            draft.qty_transfer = dtDashboard[1].jml_penerimaan;
            draft.qty_ambil = dtDashboard[2].jml_pengambilan;
          }
          
        }
        break;
    }
  });

export default HomeScreenReducer;
